import {useRef, useEffect, useState} from 'react'
import '../Navigation/Sidebar.css'
import "./OneDayOption.css"
import TopNav from '../Navigation/TopNav';
import { slide as Menu } from 'react-burger-menu'
import { Link, useLocation } from 'react-router-dom';
import IntroductionVideo from './IntroductionVideo1'
import MissionControlActivity from './MissionControlActivity';
import TimelineActivity from './TimelineActivity1'
import { useTranslation } from 'react-i18next';

function OneDayOption() {
    

 // Use the studentActivities namespace
      const {t,i18n} = useTranslation('studentActivities')

    // These variables are for the props to be passed between pages
    const location = useLocation();
    const { state } = location;
    const [missionID, setMissionID] = useState(state.mission_id);
    const [language, setLanguage] = useState(i18n.language);
    function handleClick(newLang) {
    
    }

    const introvideo = useRef(null);
    const missioncontrol = useRef(null);
    const timeline = useRef(null);
    const veterantest = useRef(null);
	const photoactivity = useRef(null);

    useEffect(()=>{
        setMissionID(state.mission_id);
        setLanguage(state.language);
        console.log("One Day Option - Mission ID: " + missionID + " - Language: " + language);
       
    }, [])


    const scrollToSection = (elementRef) => {
       
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth'
            
        });
    };

   
    
        return(
            <div>
                <TopNav onClick={handleClick}/>
        <div className=''>
     <div className=''>

        <Menu>
     <nav className='stop-nav-items'> 

     <Link to="/" style={{ textDecoration: 'none' }}>
                           
						   <i class=" fa-solid fa-house"></i> 
				 
								 
							 </Link>


             <div>
            <p className="comp-name">   {t('oneDay.title')} </p>
            <hr className='hrline' />
            </div>
             
             
            <ul>
                <li onClick = {() => scrollToSection(introvideo) }
                    className="cr-side-link"> 
                    <i class="fa-solid fa-gear"></i> 
                     {t('introductionVideo.one.title')}
                 </li>
                <li  onClick = {() => scrollToSection(missioncontrol)} 
                     className="cr-side-link"> 
                     <i class="fa-solid fa-gear"></i> 
                     {t('missionControl.title')}
                </li>
                <li  onClick = {() => scrollToSection(timeline)}
                     className="cr-side-link">
                         <i class="fa-solid fa-gear"></i> 
                      {t('timeline.one.title')}
                </li>
                {/* <li  onClick = {() => scrollToSection(veterantest)}
                         className="cr-side-link"> 
                        <i class="fa-solid fa-gear"></i> 
                    Veteran Testimonial
                  </li>
				  <li  onClick = {() => scrollToSection(photoactivity)}
                         className="cr-side-link"> 
                        <i class="fa-solid fa-gear"></i> 
                    Photo Interrogation
                  </li> */}
            </ul>

             
            </nav>  
            </Menu>
                </div>

            <div className='activity-back'> 

           
            <div className='option-summary'>
            <h2 className='sum-para-head'>  {t('oneDay.title')}</h2>
            <p className='sum-para'>  {t('oneDay.subTitle')} </p>
            
            <div className='rec-card'>

            <p>

            {t('oneDay.description')}
           
            </p>
            </div>
            
            </div>    
            
            <div ref ={introvideo}>
            
			<IntroductionVideo />
			
            </div>



            <div ref = {missioncontrol}>
            
            <MissionControlActivity />
			
            </div>



            <div ref = {timeline}>
            
			<TimelineActivity />
            
            </div>
            
			
    </div>
    </div>	
    </div>
            
        )
    }



export default OneDayOption
