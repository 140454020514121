import React from 'react';
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';

function CommemorationEvaluation({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  
     const {t} = useTranslation("studentActivities")
  
  
     
                        const textFormat = (text) =>
                           text.split('\n').map((line, index) => (
                       
                             <React.Fragment key={index}>
                               {line}
                               <br />
                             </React.Fragment>
                           ));

  return (

    
    
    <div>
      <div className='activity-sec'>
				<h2  className='activity-title'>
                  {t("evaluation.title")}
				</h2>


                <div className='activity-content'>

                <p className='mission-text'>  {t("evaluation.subTitle")} </p>
                

                <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("evaluation.collapse") : t("evaluation.expand")}</button>
          <h2>{title}</h2>

          <div {...getCollapseProps()}>{isExpanded && children}
            
                   
                
               

                <h3 className='outline-head'> {t("evaluation.objective.title")}</h3>
                <p className='outline-text'>  {t("evaluation.objective.description")} </p>

                <p className='outline-big6'>
                 <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'> {t("evaluation.objective.link")}</a>
                 {t("evaluation.objective.descriptionContinued")} 
                </p>

                <h3 className='outline-head'> {t("evaluation.actionSteps.title")}</h3>
                <p className='outline-text'> {textFormat(t("evaluation.actionSteps.description"))}
                 
              
                 

                </p>

                <h3 className='outline-head'>  {t("evaluation.questions.title")} </h3>
                <p className='outline-text'> {textFormat(t("evaluation.questions.description"))} 
                </p>


                <h3 className='outline-head'>  {t("evaluation.debrief.title")}</h3>
                <p className='outline-text'> {textFormat(t("evaluation.debrief.description"))}  </p>
                </div>
                </div>
			</div>
    </div>


  );
}

export default CommemorationEvaluation;