import React from 'react';
import { useCollapse } from 'react-collapsed';
import PhotoActivity from './PhotoActivity';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function InterrogatingPhoto({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    const {t} = useTranslation("studentActivities")

    
                   const textFormat = (text) =>
                      text.split('\n').map((line, index) => (
                  
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ));
    

  return (
    
    <div className='activity-sec'>
				<h2  className='activity-title'>
                 {t("interrogatingPhoto.title")}
				</h2>
        <div className='activity-content'>
            <p className='mission-text'>
            {t("interrogatingPhoto.subTitle")}
            </p>
            <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("interrogatingPhoto.collapse") : t("interrogatingPhoto.expand")}</button>
          <h2>{title}</h2>

          <div {...getCollapseProps()}>{isExpanded && children}
            


                  {/* <h3 className='outline-head'>Mission</h3>
                  <p className='outline-text'> Watch a video that outlines the conflict and the events that led 
                  to the need for international involvement.   </p> */}
                  

                  <h3 className='outline-head'> {t("interrogatingPhoto.objective.title")}</h3>
                <p className='outline-text'> {textFormat(t("interrogatingPhoto.objective.descriptionOne"))} 
              

                    <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>  {t("interrogatingPhoto.objective.link")}</a>  
                    {t("interrogatingPhoto.objective.descriptionContinued")}
                    {textFormat(t("interrogatingPhoto.objective.descriptionTwo"))} 
                </p>

                 

                <h3 className='outline-head'> {t("interrogatingPhoto.actionSteps.title")}</h3>
                          <p className='outline-text'> 
                          {textFormat(t("interrogatingPhoto.actionSteps.description"))}
                          
                          </p>

                

                          <h3 className='outline-head'> {t("interrogatingPhoto.questions.title")}</h3>
                <p className='outline-text'> 
                {textFormat(t('interrogatingPhoto.questions.description'))} 

                </p>

                  


                <h3 className='outline-head'>  {t("interrogatingPhoto.debrief.title")}</h3>
                <p className='outline-text'>  {textFormat(t("interrogatingPhoto.debrief.description"))}
                                  </p>


                     <PhotoActivity   />
                </div>
             
              

        </div>

		</div>
			

  );
}

export default InterrogatingPhoto;