import React, { useState } from "react";
import "./ImageSlider.css" 
import { Scrollchor } from 'react-scrollchor'; // Allows hash links (right nav dots)
import { useTranslation } from "react-i18next";

const sliderStyles = {
  position: "relative",
  height: "100%",
};


const ImageSlider = ({ photos }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePhotoClick = (photo, index) => {
    console.log("/assets/"+photo.directory);
    setSelectedPhoto(photo);
    setCurrentIndex(index);

      // Scroll to the bottom of the screen
     

  };

  const {t} = useTranslation("studentActivities")

  return (
    <div>
    <div className="img-slider" style={sliderStyles}>
    
      <div className="image-gallery">
        <Scrollchor to="#selected-photo" className="scrollchor-con">
        {photos.map((photo, index) => (
          <img
            className="image-slide"
            src={"/assets/"+photo.directory}
            key={index}
            
            onClick={() => handlePhotoClick(photo, index)}
          />
        ))}
        </Scrollchor>
      </div>
    
      </div>
      {selectedPhoto && (
        <div>

          <div className="questions">
            <ol className="o-list">
              <li>
              {t("interrogatingPhoto.photoQuestion.one")}
               
              </li>
              <li>
              {t("interrogatingPhoto.photoQuestion.two")}
             
              </li>
              <li>
              {t("interrogatingPhoto.photoQuestion.three")}
              
              </li>
              <li>
              {t("interrogatingPhoto.photoQuestion.four")}
              
              </li>
            </ol>
          </div>
          <h3 className="sel-title"> {t("interrogatingPhoto.photoQuestion.title")} </h3>
          <div className="img-container" id="selected-photo">
            <img
              className="sel-img"
              src={"/assets/"+selectedPhoto.directory}
              alt="Selected Photo"
            />
          </div>

          
        </div>
      )}
    
    </div>
  );
};

export default ImageSlider;
