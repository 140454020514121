import { useState } from 'react';
import React from 'react';
import { useCollapse } from 'react-collapsed';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CanadasRole({ title, children }) {


const {t} = useTranslation("studentActivities")
        
             const textFormat = (text) =>
                text.split('\n').map((line, index) => (
            
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ));

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  // These variables are for the props to be passed between pages
  const location = useLocation();
  console.log ("Canada's Role useLocation: " + JSON.stringify(location));
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(state.language);

  return (

    
    <div>
      <div className='activity-sec'>
				<h2  className='activity-title'>
               {t("canadasRole.title")}
				</h2>


                <div className='activity-content'>

                <p className='mission-text'>   {t("canadasRole.subTitle")} </p>
                

                <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("canadasRole.collapse") :   t("canadasRole.expand")}</button>
      <h2>{title}</h2>

      <div {...getCollapseProps()}>{isExpanded && children}
                   
                
               

                <h3 className='outline-head'> {t("canadasRole.objective.title")}</h3>
                <p className='outline-text'> {t("canadasRole.objective.description")}  </p>

                <p className='outline-big6'>
                    <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>  {t("canadasRole.objective.link")}</a>  
                    {textFormat(t("canadasRole.objective.descriptionContinued"))}
                </p>

                <h3 className='outline-head'> {t("canadasRole.actionSteps.title")}</h3>
                <p className='outline-text'> <Link to="/timeline" state={{mission_id:missionID, language:language, timeline_filter:4}}>  {t("canadasRole.actionSteps.link")}</Link> 
                {t("canadasRole.actionSteps.description")}
                {textFormat(t("canadasRole.actionSteps.descriptionContinued"))}
                </p>

                <h3 className='outline-head'> {t("canadasRole.questions.title")}</h3>
                <p className='outline-text'> 
                {textFormat(t('canadasRole.questions.description'))} 
                  

                </p>


                <h3 className='outline-head'>  {t("canadasRole.debrief.title")}</h3>
                <p className='outline-text'>  {t("canadasRole.debrief.description")}
                                  </p>
                </div>
                </div>
			</div>
    </div>
  );
}

export default CanadasRole;