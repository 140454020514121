import React, {useState} from "react";
import "./MainLandingPage.css";
import LandingNav from "../Navigation/LandingNav";
import Footer from "../Navigation/Footer";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MainLandingPage() {
  const { t, i18n } = useTranslation("mainLandingPage");

  console.log(i18n.language);
  const [language, setLanguage] = useState(i18n.language);
  function handleClick(newLang) {
    
  }
  
  return (
    <div>
      <LandingNav onClick={handleClick} />

      {/* Header Section */}
      <div className="header-section">
        <div className="header">
          <div className="header-text">
            <div className="intro-phrase">

            {t('headerSection.title')}
  
            </div>
            <div className="explore-text">

            {t('headerSection.description')}
             
            </div>
            
            <NavLink 
              state={{ mission_id: 8}}
              to={"/missions"}>
                <button className="view-missions">
                  
                {t('headerSection.buttonText')}
                  
                  </button>
            </NavLink>
          </div>
          <div className="header-image">
            <img
              className="h-img"
              loading="lazy"
              src="/main-header-image.png"
              alt="War History"
            />
          </div>
        </div>
      </div>

      {/* About Section */}

      <div className="about-container">
        <header className="about-header">
          <h1 className="about-section-title">

          {t('aboutSection.title')}
            
          </h1>
        </header>
        <div className="about-content">
          <div className="about-column">
            <p className="about-section-description">

            {t('aboutSection.description')}
           
            </p>
            <NavLink to={"/about-us"} >
              <button className="about-learn-more">

              {t('aboutSection.buttonText')}

                </button>
            </NavLink>
          </div>
          <div className="">
            <img
              className="about-image-wrapper"
              loading="lazy"
              src="/about_section_image.png"
              alt="Image"
            />
          </div>
        </div>
      </div>

      {/* Featured Mission Section  */}

      <div className="featured-missions-container">
        <img
          loading="lazy"
          src="/featured-mission-image.jpg"
          className="featured-missions-img"
        />

        <div className="mission-container">
          <p className="mission-title"> 
            
          {t('featuredSection.title')}
            
            </p>

          <div className="mission-details">
            <div className="featured-card-column">
              <div className="mission-info">
                <div className="mission-name">

                {t('featuredSection.missionOne.title.a')}
                <br/>
                {t('featuredSection.missionOne.title.b')}
                <br/>
                {t('featuredSection.missionOne.title.c')}
                  
                </div>
                <div className="f-mission-text">

                {t('featuredSection.missionOne.description')}
                
                </div>

                <NavLink
                  to={"/timeline"}
                  state={{ mission_id: 1}}
                >
                  <button className="about-learn-more">
                    
                  {t('featuredSection.missionOne.buttonText')}

                  </button>
                </NavLink>
              </div>
            </div>
            <div className="featured-card-column">
              <div className="mission-info">
                <div className="mission-name">

                {t('featuredSection.missionTwo.title.a')}
                <br/>
                {t('featuredSection.missionTwo.title.b')}
               

                </div>
                <div className="f-mission-text">

                {t('featuredSection.missionTwo.description')}

                </div>

                <NavLink
                  to={"/timeline"}
                  state={{ mission_id: 8}}
                >
                  <button className="about-learn-more">

                  {t('featuredSection.missionTwo.buttonText')}
                    
                    </button>
                </NavLink>
              </div>
            </div>
            <div className="featured-card-column">
              <div className="mission-info">
                <div className="mission-name">

                  
                {t('featuredSection.missionThree.title.a')}
                <br/>
                {t('featuredSection.missionThree.title.b')}
                <br/>
                {t('featuredSection.missionThree.title.c')}
               
                
                </div>
                <div className="f-mission-text">

                {t('featuredSection.missionThree.description')}

                </div>

                <NavLink
                  to={"/timeline"}
                  state={{ mission_id: 7}}
                >
                  <button className="about-learn-more">
                    
                  {t('featuredSection.missionThree.buttonText')}

                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Teacher Resources Section  */}

      <div className="teachers-resources-container">
        <header className="teachers-resources-header">

        {t('teachersResourcesSection.title')}
          
        </header>
        <div className="teachers-resources-description">

        {t('teachersResourcesSection.description')}
         
        </div>

        <NavLink to={"/teacher-resources"} >
          <button className="teachers-resources-button">

          {t('teachersResourcesSection.buttonText')}

          </button>
        </NavLink>
      </div>

      {/* Veteran Interviews Section  */}
      <div className="veteran-interviews-section">
        <img
          loading="lazy"
          src="/vet_image.jpg"
          className="vet-inter-image"
          alt="Veteran Interviews"
        />
        <div className="vet-inter-content">
          <header className="vet-heading">
            
          {t('veteranInterviewsSection.title')}

          </header>
          <p className="vet-description">

          {t('veteranInterviewsSection.description')}
            
          </p>

          <NavLink to={"/veteran-interviews"}>
            <button className="about-learn-more">
            {t('veteranInterviewsSection.buttonText')}
              </button>
          </NavLink>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default MainLandingPage;
