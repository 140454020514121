import React from "react";
import './About.css';
import LandingNav from '../Navigation/LandingNav';
import Footer from '../Navigation/Footer';
import { NavLink, useLocation } from 'react-router-dom';
import Accordion from '../Accordion/Accordion';
import { useTranslation } from 'react-i18next';

    
function About(){

  const {t, i18n} = useTranslation("about");
   
  function handleClick(newLang) {
    
  }

  return (
      <div>

      <LandingNav onClick={handleClick} />
      {/* <LandingNav onClick={handleClick} state={{language: language}} /> */}


{/* About Header Section */}
        <div className="about-h-section">
  <div className="about-h-text">
    <div className="about-h-text-section">
      <h1 className="about-h-title"> {t("headerSection.titleOne")}</h1>
      <h2 className="about-h-subtitle"> {t("headerSection.titleTwo")}</h2>
      <p className="about-h-description">
       
      {t("headerSection.description")}

      </p>
      
    </div>
  </div>
  <div className="about-h-picture">
    <img
      className="about-h-img"
      src='about_header.png'
      alt=""
    />
  </div>
</div>
  



{/* Our Mission Section */}


<div className='our-mission-section'>

<div className='our-mission'>
<h3 className='our-mission-heading'> {t("missionSection.title")}</h3>

<p class="our-mission-dash">
 _
</p>

<p className='our-mission-text'> 
  
{t("missionSection.description")}


</p>
   </div>
  
  <div className='a-project-desc'>

      <div className='project-desc-image-con'>
      <img
      className="project-desc-image"
      src='project-description-image.jpg'
      alt=""
    />
      </div>


      <div className='project-desc-text-con'>

        <h3 className='project-desc-heading'> {t("projectSection.title")}</h3>

        <p class="our-mission-dash-2">
 _
        </p>

        <p className='project-desc-text'>

        {t("projectSection.description")}

        </p>


      </div>


  </div>

</div>




{/* About CTA (Call To Action) Section */}


<div className='about-cta-section'>

  
      <img
        loading="lazy"
        src='/about-cta-image.jpg'
        className='about-cta-image'
        />

  <div className='about-cta-content'>

  <h2 className='about-cta-heading'> 

  {t("callToAction.title")}
 
 
  </h2>

  <NavLink to={"/"} >
          <button className="about-cta-button">{t("callToAction.buttonText")}</button>
         </NavLink>

  </div>

</div>




{/* About FAQ  Section */}

{/* <div className='about-faq-section'>
<h2 className='about-faq-heading'>
  FAQS
</h2>

<Accordion allowMultipleOpen>
        <div label='Which mission(s) should we study?' isOpen>
          <p>
            As a teacher, you can decide to give each student a choice of what mission they would like to research, which helps to give ownership of their own learning pathway. Students can then compare the different missions that Canada has served in to better understand the similarities and differences of service.
          </p>
          <p>
            However, it may also be beneficial to look at a specific mission as an entire class and see what themes the students can identify.
          </p>
          <p>
            More missions will be added over the next several years starting with: UNEF I, and the War in Afghanistan.
          </p>
          <br/>
        </div>


        <div label='Question 2'>
            <p>

          
            </p>
            <br/>
            <p>Usionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, 
              probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet 
              salutandi. Fugit expetenda interesset no, probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum 
              accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda usionemque eam, mel ad erat cum </p>
        </div>

        <div label='Question 3'>
            <p>
          Usionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, probo 
          eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi.\
           Fugit expetenda interesset no, probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan 
           noluisse, nostrum accumsan movet salutandi. Fugit expetenda 
          
          </p>
          <br/>
          <p>Usionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, 
            probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet 
            salutandi. Fugit expetenda interesset no, probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum 
            accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda usionemque eam, mel ad erat cum </p>
        </div>

        <div label='Question 4'>
            <p>
          Usionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, probo 
          eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi.\
           Fugit expetenda interesset no, probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan 
           noluisse, nostrum accumsan movet salutandi. Fugit expetenda 
          
          </p>
          <br/>
          <p>Usionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, 
            probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet 
            salutandi. Fugit expetenda interesset no, probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum 
            accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda usionemque eam, mel ad erat cum </p>
        </div>

        <div label='Question 5'>
            <p>
          Usionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, probo 
          eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi.\
           Fugit expetenda interesset no, probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan 
           noluisse, nostrum accumsan movet salutandi. Fugit expetenda 
          
          </p>
          <br/>
          <p>Usionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, 
            probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet 
            salutandi. Fugit expetenda interesset no, probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum 
            accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda usionemque eam, mel ad erat cum </p>
        </div>
      </Accordion>

</div> */}


        <Footer/>


        </div>






        )
    
    }
  
    export default About;
  
  
        