import i18n from "i18next";
import { initReactI18next } from "react-i18next";


//importing english translation files
import enCommon from '../locales/en/common.json';
import enMainLandingPage from '../locales/en/mainLandingPage.json';
import enMissions from '../locales/en/missions.json';
import enTimeline from '../locales/en/mainTimeline.json';
import enAbout from '../locales/en/about.json';
import enTeam from '../locales/en/team.json';
import enTeacherResources from '../locales/en/teacherResources.json';
import enContact from '../locales/en/contact.json';
import enVeteranInterviews from '../locales/en/PK75.json';
import enStudentActivities from '../locales/en/studentActivities.json';


//importing french translation files
import frCommon from '../locales/fr/common.json';
import frMainLandingPage from '../locales/fr/mainLandingPage.json';
import frMissions from '../locales/fr/missions.json';
import frTimeline from '../locales/fr/mainTimeline.json';
import frAbout from '../locales/fr/about.json';
import frTeam from '../locales/fr/team.json';
import frTeacherResources from '../locales/fr/teacherResources.json';
import frContact from '../locales/fr/contact.json';
import frVeteranInterviews from '../locales/fr/PK75FR.json';
import frStudentActivities from '../locales/fr/studentActivities.json';


const savedLang = localStorage.getItem('language') || 'en'; //get the saved language or set it to "en" if unavailable

i18n
  .use(initReactI18next) 
  .init({
    lng: savedLang,  // Set the default language as the saved language 
    fallbackLng: 'en',  // Fallback language if a key is missing in the current language
    debug: true,
    resources: {
      en: {
        common: enCommon,
        mainLandingPage: enMainLandingPage,
        missions: enMissions,
        timeline: enTimeline,
        about: enAbout,
        team: enTeam,
        teacherResources: enTeacherResources,
        contact: enContact,
        veteranInterviews: enVeteranInterviews,
        studentActivities: enStudentActivities
      
      },

      fr: {
        common: frCommon,
        mainLandingPage: frMainLandingPage,
        missions: frMissions,
        timeline: frTimeline,
        about: frAbout,
        team: frTeam,
        teacherResources: frTeacherResources,
        contact: frContact,
        veteranInterviews: frVeteranInterviews,
        studentActivities: frStudentActivities
      },
      
    },
    ns: ['common', 'mainLandingPage', 'missions', 'timeline', 'about', 'team', 'teacherResources', 'contact', 'veteranInterviews', 'studentActivities'], // Namespaces to be loaded
    defaultNS: 'common',  // Default namespace
    interpolation: {
      escapeValue: false,
    },
  });

  export default i18n;