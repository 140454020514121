import { useState } from 'react';
import React from 'react';
import { useCollapse } from 'react-collapsed';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function TimelineActivity5({ title, children }) {

  const {t} = useTranslation("studentActivities")
        
             const textFormat = (text) =>
                text.split('\n').map((line, index) => (
            
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ));



  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  // These variables are for the props to be passed between pages
  const location = useLocation();
  console.log ("Timeline5 useLocation: " + JSON.stringify(location));
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(state.language);

  return (
    
    
    
    
        <div>
    
    
          <div className='activity-sec' >
                    <h2  className='activity-title'>
                    {t("timeline.five.title")}
                    </h2>
                    <div className='activity-content'>
                        
                    
                    <p className='mission-text'>  {t("timeline.five.subTitle")}</p>
    
                    <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("timeline.five.collapse") : t("timeline.five.expand")}</button>
          <h2>{title}</h2>
    
          <div {...getCollapseProps()}>{isExpanded && children}
                    
    
                    <h3 className='outline-head'> {t("timeline.five.objective.title")}</h3>
                    <p className='outline-text'>  {textFormat(t("timeline.five.objective.description"))}
                    
                     <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'> 
                     
                      {t("timeline.five.objective.link")}
                      
                       </a> 
                       {textFormat(t("timeline.five.objective.descriptionContinued"))}
                      
                    </p>
    
                    <h3 className='outline-head'>  {t("timeline.five.actionSteps.title")} </h3>
                    <p className='outline-text'> 
                        <Link to="/timeline" state={{mission_id:missionID, language:language, timeline_filter:1}}> {t("timeline.five.actionSteps.link")}</Link> {t("timeline.five.actionSteps.description")}
                     {textFormat(t("timeline.five.actionSteps.descriptionContinued"))}
                    </p>
    
                    <h3 className='outline-head'> {t("timeline.five.questions.title")}</h3>
                    <p className='outline-text'> 
                    {textFormat(t('timeline.five.questions.description'))} 
                      
    
                    </p>
    
    
                    <h3 className='outline-head'> {t("timeline.five.debrief.title")}</h3>
                    <p className='outline-text'> 
    
    
                    {t("timeline.five.debrief.description")} <Link to="/mission-control" state={{mission_id:missionID, language:language}}>{t("timeline.five.debrief.link1")}</Link>. <br /> 
                       
                       
                       
                    {textFormat(t("timeline.five.debrief.descriptionContinued"))}
                      
                        <ul>
                            <li> <Link to="/mission-control" state={{mission_id:missionID, language:language}}>  {t("timeline.five.debrief.link2")}</Link></li>
                            <li> <Link to="/mission-control" state={{mission_id:missionID, language:language}}>   {t("timeline.five.debrief.link3")}</Link></li>
                        </ul>
    
                    </p>
                    
                    </div>
                    </div>
    
                </div>
        </div>
    
    


  );
}

export default TimelineActivity5;