import React, { useState } from "react";
import './TopNav.css';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//const TopNav = () => {
const TopNav = ({ onClick }) => {

  
    const {t, i18n} = useTranslation("common")
  
      // Function to change between languages (English and French)
      const toggleLanguage = () => {
        const currentLang = i18n.language; // Get the current language
        const newLang = currentLang === 'en' ? 'fr' : 'en'; // Toggle between 'en' and 'fr'
        i18n.changeLanguage(newLang); // Change to the new language
        localStorage.setItem('language', newLang); // Save selected language
    
        window.location.reload();
        onClick(newLang); // Keep track of the language variable using the callback
      };
  

      // These variables are for the props to be passed between pages
    const location = useLocation();
    const { state } = location;
    
  return (
    <>
      <div className='nav-div'>
        <nav className='top-nav-items'>
          <div className='logo-section'>
            <Link to='/' state={state}>
              <img className='gregg-logo-tn' src='/gregg_logo.png' alt='Logo' />
            </Link>
            <Link to='/' style={{ textDecoration: 'none' }} state={state}>
              <h1 className='land-topnav-logo'> {t('navigation.title')}</h1>
            </Link>
          </div>
          <div className='menu-icon'></div>
          <ul className='topnav-menu'>
            
              <li>
                <div className='navlink-container'>
                  <NavLink className= "nav-links"  to= "/mission-control" state={state}>
                   {t('navigation.missionControl')}
                  </NavLink>
                  <NavLink className= "nav-links-timeline"  to= "/timeline" state={state}>
                  {t('navigation.timeline')}
                  </NavLink>
                  <NavLink className= "nav-links-activities"  to= "/student-teacher-activities" state={state}>
                  {t('navigation.activities')}
                  </NavLink>
                 
                </div>
              </li>
           
          </ul>
          
          <NavLink to="#" className="lang-sel-btn-two" onClick={toggleLanguage} state={state}>
                  
                   
                  <img className="lang-logo" src="/lang-logo.png"/>
                   
                  
                  {t('navigation.language')}
                  
                  
                  </NavLink>
        </nav>
      </div>
    </>
  );
};

export default TopNav;
