import React, { useRef, useEffect, useState } from "react";
import injectSheet from "react-jss";
import $, { data } from "jquery";
import { Scrollama, Step } from "react-scrollama";
import { Scrollchor } from 'react-scrollchor'; // Allows hash links (right nav dots)
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import * as turf from "@turf/turf";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { Chrono } from "react-chrono";
import TopNav from "../Navigation/TopNav";
import HtmlReactParser from 'html-react-parser';
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../Navigation/Sidebar.css";
import "./Timeline.css";
import { slide as Menu } from "react-burger-menu";
import ReactAudioPlayer from 'react-audio-player';
mapboxgl.accessToken =
  "pk.eyJ1IjoibGZjaG9ja2V5IiwiYSI6ImNqdHQ3OTJzYTAzbDM0NG9hcDJhcHM3YXgifQ.-xAMcIMztR--wvn0P57RnQ";

const styles = {
  navbar: {
    position: "fixed",
    display: "flex",
    top: 0,
    right: 0,
    zIndex: 1,
    "& a": {
      display: "block",
      fontSize: "20px",
      padding: "20px",
    },
  },
  pageTitle: {
    textAlign: "center",
    fontSize: 22,
    margin: "90px 0 10px",
    visibility: "hidden",
  },
  description: {
    maxWidth: 600,
    margin: "10px auto 30px",
    fontSize: 22,
    lineHeight: "28px",
    "& a": {
      color: "black",
    },
  },
  pageSubtitle: {
    textAlign: "center",
    fontSize: 22,
    color: "#888",
  },
  graphicContainer: {
    padding: "40vh 2vw 20vh",
    display: "flex",
    justifyContent: "space-between",
  },
  graphic: {
    flexBasis: "60%",
    position: "sticky",
    width: "100%",
    height: "60vh",
    top: "20vh",
    backgroundColor: "#aaa",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      fontSize: "5rem",
      fontWeight: 700,
      textAlign: "center",
      color: "#fff",
    },
  },
  scroller: {
    flexBasis: "35%",
  },
  steps: {
    margin: "0 auto 3rem auto",
    padding: "180px 0",
    border: "1px solid #333",
    "& p": {
      textAlign: "center",
      padding: "1rem",
      fontSize: "1.8rem",
      margin: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  button: {
    backgroundColor: "#3773ac",
    color: "white",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "6px",
    textAlign: "center",
    display: "block",
    maxWidth: 220,
    margin: "10px auto 30px",
    fontSize: 19,
    lineHeight: "28px",
    textDecoration: "none",
  },
  subhed: {
    maxWidth: 600,
    margin: "10px auto 15px",
    fontSize: 22,
    lineHeight: "28px",
    "& a": {
      color: "black",
    },
    textAlign: "center",
  },
  whoUsing: {
    maxWidth: 960,
    margin: "30px auto 100px",
    fontSize: 19,
    lineHeight: "26px",
    gridAutoRows: "minmax(100px, auto)",
    "& a": {
      color: "black",
    },
    "& img": {
      width: "100%",
    },
    display: "grid",
    gridTemplateColumns: "2fr 5fr",
    "& > div": {
      padding: "16px 0",
      borderTop: "1px solid #ccc",
      "&:nth-child(odd)": {
        paddingRight: "13px",
        borderRight: "1px solid #ccc",
      },
      "&:nth-child(even)": {
        paddingLeft: "13px",
      },
    },
  },
};

const Map3DScrollama = () => {

  const {t, i18n} = useTranslation("timeline");
  const { t: tCommon } = useTranslation("common"); // Access the "common" namespace

  // These variables are for the props to be passed between pages
  const location = useLocation();
  //console.log ("Timeline useLocation: " + JSON.stringify(location));
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(i18n.language);
  function handleClick(newLang) {
    
    // setLanguage(newLang);
    // console.log("Timeline handleClick new language: " + newLang + ". Now choose proper json file.");
    // getData(missionID);
  }
  const [timelineFilter, setTimelineFilter] = useState(state.timeline_filter);
  
  const [jsonFileLoaded, setJSONFileLoaded] = useState(false);
  

  useEffect(()=>{
		// setMissionID(location.mission_id);
    // // Get the timeline.json file loaded
    // console.log("Mission ID: " + missionID);
    
    // setLanguage(location.language);
    // setTimelineFilter(location.timeline_filter);
    // console.log("Timeline === Mission ID: " + missionID + " - Language: " + language + " - Filter: " + timelineFilter);
    getData(missionID); 
	}, [])

  //BEGINING OF MAP FUNCTIONALITY IMPLEMENTATION
  const mapContainer = useRef(null);
  const map = useRef(null);
  //var steps = [];
  const [timeline_data, setResult] = useState({});
  const [newTimes, setFilteredTimes] = useState([]);
  const [initialTime, setInitialTime] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  const [mapLayersLoaded, setMapLayersBool] = useState(false);
  const [toggleableLayerIds, setToggleableLayerIds] = useState([]); // This array keeps track of all layers (numeric id) added to the map and whether they are hidden

  
  // Loads the appropriate JSON 
  const getData = (m_id) => {
      //console.log("Inside getData1 and the language that is returned is: " + language); 
      // If the jsonFileLoaded is already loaded, we need to check to see if the language has recently been changed
      if (jsonFileLoaded && language != i18n.language){
        setLanguage(i18n.language);
        setJSONFileLoaded(false);
      }

      //console.log ("getData2: the language is now: " + language + " - i18next: " + i18n.language + " and the jsonFileLoaded: " + jsonFileLoaded);
      if (!jsonFileLoaded){
        console.log("Inside getData3 and the language that is returned is: " + i18n.language);
        var json_lang_file = '../assets/'+m_id+'/timeline.json';
        if (i18n.language == 'fr'){
          json_lang_file = '../assets/'+m_id+'/timeline_fr.json';
        }
        fetch(json_lang_file, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        })
        .then(response => response.json())
        .then(myJson => {
          setResult(myJson);
          //console.log("The result should be set: " + JSON.stringify(myJson));
          //console.log("timeline_date: " + JSON.stringify(timeline_data)); // probably won't be set yet
          setJSONFileLoaded(true);
        })
        .catch(error => {
          console.error('Error:', error);
        }); 
      }
  };

  // Initialize the map
  //      Also, add all of the layers we will use
  useEffect(() => {
    
    console.log("newTimes elements in array: " + newTimes.length + " . jsonFileLoaded = " + jsonFileLoaded);
    if (jsonFileLoaded){
      if (firstRender) {
        window.scrollTo(0, 0);
        setFirstRender(false);

        var new_times = [];
        // Load all of the timeline_ids into an array to be used to trigger and track scrollama components
        var counter = 0;
        timeline_data.timeline.map((time) => {
          if (counter == 0){
            setInitialTime(time);
            console.log("timeline.json currently looping through the file (first iteration): " + JSON.stringify(time) + " - initialTime: " + JSON.stringify(initialTime));
          }
          counter++;
          //steps.push(time.timeline_id);
          new_times.push(time);
        });
        setFilteredTimes(new_times);
        filterTimeline(timelineFilter)
        //console.log("First render with timeline_filter: " + timelineFilter);
      }
      // // Load all of the timeline_ids into an array to be used to trigger and track scrollama components
      // timeline_data.timeline.map((time) => {
      //     steps.push(time.timeline_id);
      // }); 

      //console.log("useEffect timeline");
      if (map.current) return; // initialize map only once
      console.log("map is not loaded");
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/lfchockey/clid5nak2002p01p6hd1f9kb4', //'mapbox://styles/mapbox/satellite-streets-v12', //"mapbox://styles/lfchockey/clid5nak2002p01p6hd1f9kb4", //'mapbox://styles/mapbox/satellite-v9', //
        center: [40, -30],
        zoom: 1.5,
        projection: "mercator",
      });

      // map.current.on('moveend', async () =>{
      //   //console.log("The map has finished moving and flying");
      //   let newZoom = map.current.getZoom();
      //   //changeOpacity(newZoom);
      // });



      // When the map loads, add the sources and layers to use for polygons, country colours, arrows, etc.
      map.current.on("load", () => {
        const layers = map.current.getStyle().layers;
        // Find the index of the first symbol layer in the map style.
        //    The highlighted-countries layer will be placed below this one in the map layer stack
        let firstSymbolId;
        for (const layer of layers) {
          //console.log(layer.type + " - " + layer.id);
          if (layer.type === "symbol") {
            firstSymbolId = layer.id;
            break;
          }
        }

        

        // Add source for country polygons using the Mapbox Countries tileset
        // The polygons contain an ISO 3166 alpha-3 code which can be used to for joining the data
        // https://docs.mapbox.com/vector-tiles/reference/mapbox-countries-v1
        map.current.addSource("countries", {
          type: "vector",
          url: "mapbox://mapbox.country-boundaries-v1",
        });

        // Add 3D terrain
        map.current.addSource("mapbox-dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.mapbox-terrain-dem-v1",
          tileSize: 512,
          maxzoom: 14,
        });
        // add the DEM source as a terrain layer with exaggerated height
        map.current.setTerrain({ source: "mapbox-dem", exaggeration: 5 });

        function createCountryLayer(id, color) {
          return {
            id: id,
            type: "fill",
            source: "countries",
            "source-layer": "country_boundaries",
            paint: {
              "fill-outline-color": "#484896",
              "fill-color": color,
              "fill-opacity": [
                'interpolate',
                ['linear'],
                ['zoom'],
                2,0.8,10,0.2]
              //0.4,
            },
            // Display none by adding a filter with an empty string.
            filter: ["in", "iso_3166_1", ""],
          };
        }

        map.current.addLayer(
          createCountryLayer("countries-highlighted1", "#44bef1"),
          firstSymbolId
        );
        map.current.addLayer(
          createCountryLayer("countries-highlighted2", "#b18f06"),
          firstSymbolId
        );
        map.current.addLayer(
          createCountryLayer("countries-highlighted3", "#0623b1"),
          firstSymbolId
        );
        map.current.addLayer(
          createCountryLayer("countries-highlighted4", "#eb0f0f"),
          firstSymbolId
        );
        map.current.addLayer(
          createCountryLayer("countries-highlighted5", "#1c8112"),
          firstSymbolId
        );

        map.current.addSource("polygonSource", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [[]],
            },
          },
        });

        map.current.addLayer({
          id: "polygonLayer",
          type: "fill",
          source: "polygonSource",
          layout: {},
          paint: {
            "fill-color": "#0080ff",
            "fill-opacity": 0.5,
          },
        });

        // Add populated places
        map.current.addSource('populated-places-feature-rank', {
            type: 'vector',
            url: 'mapbox://examples.populated-places-label-rank'
        });

        map.current.addLayer({
            'id': 'populated-places-feature-rank-id',
            'type': 'circle',
            'source': 'populated-places-feature-rank',
            'source-layer': 'city_labels',
            'paint': {
                'circle-radius': 3,
                'circle-color': '#ff69b4'
            }
        });

        setMapLayersBool(true); // This is used to keep track of whether the map layers have been loaded so colours can be adjusted
      });

      
    }
  });

  // This handles the loading of the initial timeline event/time
  useEffect(() => {
    if (jsonFileLoaded){
      const loadFirstTime = async () => {
        //console.log("LoadFirstTime async function called")
        if (mapLayersLoaded && initialTime){
          // *** Load initial map
          clearMapCountryColours(); 
          clearMapAnimations();
          
          console.log("Initial Time (after map layers are loaded): " + JSON.stringify(initialTime));
          if (initialTime){
            console.log ("Initial Time is set ~~~~~~~~~~~~~~~~~~~~")
            var bbox_arr = initialTime.bounding_box_arr;
            if (bbox_arr != "" && bbox_arr != null && bbox_arr != 'null'){
                // If we have a bounding box 
                var map_options = { 
                                    easing: easingFunctions["linear"],
                                    duration: 4000,
                                    bearing: 0, //time.bearing, 
                                    pitch: 0 //time.pitch  
                                };
                setBoundingBox(bbox_arr, map_options);
            }
            else{
                flyToLocation(initialTime.latitude, initialTime.longitude, initialTime.zoom, initialTime.pitch, initialTime.bearing);
            }
            changeCountryColours(initialTime.countries_json);
            loadMapAnimations(initialTime);

            console.log("Inside loadFirstTime map function with timelineFilter: " + timelineFilter);
          }
        }
        
      }
      loadFirstTime()
    }
  }, [mapLayersLoaded, initialTime]);

  

  function clearMapCountryColours() {
    if (!map.current) return; // The back button may trigger clearing the map before it loads the layers
    if (mapLayersLoaded) {
      map.current.setFilter("countries-highlighted1", ["in", "iso_3166_1", ""]);
      map.current.setFilter("countries-highlighted2", ["in", "iso_3166_1", ""]);
      map.current.setFilter("countries-highlighted3", ["in", "iso_3166_1", ""]);
      map.current.setFilter("countries-highlighted4", ["in", "iso_3166_1", ""]);
      map.current.setFilter("countries-highlighted5", ["in", "iso_3166_1", ""]);
    }
  }

	function flyToLocation(lat = 0.0, lng = 0.0, zoom = 5, pitch = 0, bearing = 0){
        //console.log("Bearing = " + bearing);
		var animationOptions = {
		  center: [lng, lat],
		  duration: 7500,
		  easing: easingFunctions["linear"],
		  offset: [0, 0],
		  bearing: bearing,
		  zoom: zoom,
		  pitch: pitch,
		  animate: true,
		  essential: true // animation will happen even if user has `prefers-reduced-motion` setting on
		};
	
		map.current.flyTo(animationOptions);
	}

    // Fit the map to a bounding box defined for each country in the database. Only used in alliances.js 
    function setBoundingBox(bbox, options){
        //console.log("Bounding box array from db: " + bbox);
        if (bbox != "" && bbox != null && bbox != 'null'){
            bbox = bbox.replace(/\[/g, ''); //.replace('[','');
            bbox = bbox.replace(/\]/g, '');
            var coords_arr = bbox.split(',');
            if (coords_arr.length == 4){
                //console.log(coords_arr);
                var sw = new mapboxgl.LngLat(coords_arr[0], coords_arr[1]);
                var ne = new mapboxgl.LngLat(coords_arr[2], coords_arr[3]);
                var llb = new mapboxgl.LngLatBounds(sw, ne);
                // var newZoom = llb.getZoom();
                // console.log("My new zoom level from the LngLatBounds is: " + newZoom);
                map.current.fitBounds(llb, options, { padding: 0 });
            }
        }
    }

	// // Accepts an array of iso2 (countries_arr) and the colour as a string id 
	// function changeActiveCountryColour(countries_arr, colour) {
	// 	//console.log("inside changeActiveCountryColour country: " + countries_arr);
	
	// 	// Create the options for the filter
	// 	var options = ['in', 'iso_3166_1']; 
	
	// 	if (countries_arr.length > 0){
	// 		// Push the names of the countries to be filtered onto the end of the array
	// 		countries_arr.forEach(c => {
	// 			options.push(c);
	// 		}); 
	// 	}
	
	
	// 	// This filters the appropriate alliances based on the colour found
	// 	if (colour === "UNBlue"){
	// 		map.current.setFilter('countries-highlighted1', options);
	// 	}
	// 	else if (colour === "mainGold"){
	// 		map.current.setFilter('countries-highlighted2', options);
	// 	}
	// 	else if (colour === "positiveBlue") {
	// 		map.current.setFilter('countries-highlighted3', options);
	// 	}
	// 	else if (colour === "negativeRed"){
	// 		map.current.setFilter('countries-highlighted4', options);
	// 	}
	// 	else if (colour === "otherGreen"){
	// 		map.current.setFilter('countries-highlighted5', options);
	// 	}
	// 	else
	// 	{
	// 		map.current.setFilter('countries-highlighted1', options); 
	// 	}
	// }
	
	
	// Accepts a JSON object of colours and arrays of iso2 country ids 
	function changeCountryColours(countries_arr) {
    if (mapLayersLoaded){
      countries_arr = cleanJSONCountries(countries_arr);
      // if (isJsonString(countries_arr)){
      // 	console.log("JSON is valid");
      // 	console.log(JSON.stringify(countries_arr));
      // }
      // else {
      // 	console.log("There is something wrong with the countries_arr parsed as JSON");
      // }
      //console.log("changeCountryColours() country: " + JSON.stringify(countries_arr));
      //countries_arr = JSON.parse(JSON.stringify(countries_arr));
      let masterFilter = [
        'all',
        ['any',
          ['==', 'all', ['get', 'worldview']],
          ['in', 'US', ['get', 'worldview']],
        ],
        ['==', 'false', ['get', 'disputed']],
        ['any', ['all', ['in', ['get', 'iso_3166_1'], ["literal", []]]]],
      ];
      const colorOptions = {
          red: { filter: 'countries-highlighted4', options: ['in', 'iso_3166_1'] },
          blue: { filter: 'countries-highlighted3', options: ['in', 'iso_3166_1'] },
          gold: { filter: 'countries-highlighted2', options: ['in', 'iso_3166_1'] },
          green: { filter: 'countries-highlighted5', options: ['in', 'iso_3166_1'] },
          un: { filter: 'countries-highlighted1', options: ['in', 'iso_3166_1'] }
      };
      
      for (const color of Object.keys(colorOptions)) {
        //const colorData = JSON.parse(countries_arr[color]);
        // colorData should be an array of country objects
        const colorData = countries_arr[color];
        //console.log(color + " - " + colorData);
        if(typeof colorData !== "undefined") {
          if (colorData.length > 0) {
              //console.log(`${color} ${JSON.stringify(colorData)}`);
              const options = colorOptions[color].options;
              var countries = [];
              colorData.forEach(country => {
                  if (country.iso2.includes(",")) {
                      const countries_arr = country.iso2.split(',');
                      countries_arr.forEach(c => {
                          options.push(c);
                          countries.push(c)
                      });
                  }
                  options.push(country.iso2);
                  countries.push(country.iso2);
              });
              let masterFilter = [
                'all',
                ['any',
                  ['==', 'all', ['get', 'worldview']],
                  ['in', 'US', ['get', 'worldview']],
                ],
                ['==', 'false', ['get', 'disputed']],
                ['any', ['all', ['in', ['get', 'iso_3166_1'], ["literal", countries]]]],
              ];
              map.current.setFilter(colorOptions[color].filter, masterFilter);
              //map.current.setFilter(colorOptions[color].filter, ["match", ["get", "worldview"], ["all", "US"], true, false]);
          }
        }
      }
    }
	}
	
	function isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}
	
	// Some of the JSON read from the timeline.json needs cleaning before it's usable
	function cleanJSONCountries(json_str){

		json_str = JSON.stringify(json_str);
		json_str = json_str.substring(1, json_str.length-1);
		json_str = json_str.replace(/\\/g, "");
		json_str = JSON.parse(json_str)

    return json_str;
  }

  // Load the current timeline time's arrows, polygons and images
  //      This is called in the timeline.js timeSectionClicked()
  function loadMapAnimations(time) {
    // Load arrows if the current time has any
    const arrows_arr = time.arrows;
    console.log("Arrows: " + arrows_arr);
    if (arrows_arr.length > 0) {
      //map.current.setProjection('mercator');
      addAllArrows(arrows_arr);
    }

    // Load polygons - get the starting and ending polygons (originally taken from Mapbox dataset and structured as JSON)
    const polygons = time.polygons;
    console.log("Polygons: " + polygons);
    // See if a starting polygon exists
    if (polygons.starting_poly !== "" && polygons.starting_poly !== "0" && polygons.starting_poly !== null) {
      const poly1_feature_collection = JSON.parse(polygons.starting_poly);
      const poly1 =
        poly1_feature_collection.features[0].geometry.coordinates[0];

      if (polygons.ending_poly != "" && polygons.ending_poly != null) {
        const poly2_feature_collection = JSON.parse(polygons.ending_poly);
        const poly2 =
          poly2_feature_collection.features[0].geometry.coordinates[0];
        if (poly1 !== "" && poly2 !== "") {
          map.current.setLayoutProperty(
            "polygonLayer",
            "visibility",
            "visible"
          );
          addPolygonAnimation(poly1, poly2);
        }
      } else {
        // Display only the one poly without animation
        map.current.setLayoutProperty("polygonLayer", "visibility", "visible");
        addSinglePolygon(poly1);
      }
    }

    // Load images
    const images = time.images;
  }

  // Clears the map by turning specific (i.e. arrows) layers to the visibility hidden state
  function clearMapAnimations() {
    console.log("CLEARING MAP ANIMATIONS");
    if (mapLayersLoaded) {
      map.current.setLayoutProperty("polygonLayer", "visibility", "none");

      // Loop through all layers with arrows and hide them
      //console.log("toggleableLayerIds: " + toggleableLayerIds);
      toggleableLayerIds.forEach((layer) => {
        const lyr_id = "layer_" + layer;
        //console.log("Layer visibility is none: " + lyr_id);
        map.current.setLayoutProperty(lyr_id, "visibility", "none");
      });

      map.current.repaint = true;
    }
  }

  function addAllArrows(arrows) {
    // Loop through all arrows
    arrows.forEach((arrow) => {
      const arrow_id = arrow.arrow_id;
      // Check to see if the arrow layer has already been added
      if (toggleableLayerIds.includes(arrow_id)) {
        const lyr_id = "layer_" + arrow_id;
        // Make the layer visible
        map.current.setLayoutProperty(lyr_id, "visibility", "visible");
      } else {
        // Create a new layer and add the arrow
        setToggleableLayerIds((oldArray) => [...oldArray, arrow_id]);
        //toggleableLayerIds.push(arrow_id); // old method for adding layer id before hooks
        //console.log("toggleableLayerIds: " + toggleableLayerIds);
        const lyr_id = "layer_" + arrow_id;
        const lng_lat = arrow.lng_lat.split(",").map(Number);
        const rot = arrow.rotate_v3.split(",").map(Number);
        const arrow_length = arrow.length_v3.split(",").map(Number);
        const arrow_type = arrow.direction;

        // Found in mapAnimation.js
        //  This creates a new custom layer for the arrow, then adds it to the map
        const arrow_layer = addArrow(
          lyr_id,
          map,
          lng_lat,
          rot,
          arrow_length,
          arrow_type
        );
        map.current.addLayer(arrow_layer, "satellite");
        map.current.setLayoutProperty(lyr_id, "visibility", "visible");
      }
    });
  }

  function addArrow(
    layer_id,
    map,
    lng_lat,
    rotate,
    length_arr,
    arrow_direction
  ) {
    // parameters to ensure the model is georeferenced correctly on the map
    const modelOrigin = [lng_lat[0], lng_lat[1]];
    const modelAltitude = 1000;
    const modelRotate = rotate;
    //console.log("Rotate: " + modelRotate);

    var arrow_array = {
      left_red: "left_arrow_big_red.glb",
      straight_red: "straight_arrow_big_red.glb",
      right_red: "right_arrow_big_red.glb",
      left_blue: "left_arrow_big_blue.glb",
      straight_blue: "straight_arrow_big_blue.glb",
      right_blue: "right_arrow_big_blue.glb",
      left_green: "left_arrow_big_green.glb",
      straight_green: "straight_arrow_big_green.glb",
      right_green: "right_arrow_big_green.glb",
      left_gold: "left_arrow_big_gold.glb",
      straight_gold: "straight_arrow_big_gold.glb",
      right_gold: "right_arrow_big_gold.glb",
      left_UN: "left_arrow_big_UN.glb",
      straight_UN: "straight_arrow_big_UN.glb",
      right_UN: "right_arrow_big_UN.glb",
    };

    var arrow_type = "../../models/left_arrow_big.glb"; // blue arrow as default
    if (arrow_array[arrow_direction]) {
      arrow_type = "../../models/" + arrow_array[arrow_direction]; // ~~~~**** need to pass the mission_id as a parameter
    }

    const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
      modelOrigin,
      modelAltitude
    );

    // transformation parameters to position, rotate and scale the 3D model onto the map
    const modelTransform = {
      translateX: modelAsMercatorCoordinate.x,
      translateY: modelAsMercatorCoordinate.y,
      translateZ: modelAsMercatorCoordinate.z,
      rotateX: modelRotate[0],
      rotateY: modelRotate[1],
      rotateZ: modelRotate[2],
      /* Since the 3D model is in real world meters, a scale transform needs to be
       * applied since the CustomLayerInterface expects units in MercatorCoordinates.
       */
      scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
    };

    //const THREE = window.THREE;
    const clock = new THREE.Clock();
    let mixer;
    let camera;
    let scene;
    let renderer;
    let model;
    let delta;

    // configuration of the custom layer for a 3D model per the CustomLayerInterface
    var customLayer = {
      id: layer_id,
      type: "custom",
      renderingMode: "3d",
      onAdd: function (map, gl) {
        camera = new THREE.PerspectiveCamera(
          65,
          window.innerWidth / window.innerHeight,
          0.1,
          20000
        );
        // camera = new THREE.Camera();
        scene = new THREE.Scene();

        // create two three.js lights to illuminate the model
        const directionalLight = new THREE.DirectionalLight(0xffffff);
        directionalLight.position.set(0, -70, 100).normalize();
        scene.add(directionalLight);

        const directionalLight2 = new THREE.DirectionalLight(0xffffff);
        directionalLight2.position.set(0, 70, -100).normalize();
        scene.add(directionalLight2);

        // use the three.js GLTF loader to add the 3D model to the three.js scene
        const loader = new GLTFLoader();
        loader.load(arrow_type, (gltf) => {
          model = gltf.scene.children[0];
          model.castShadow = true;
          scene.add(model);
          mixer = new THREE.AnimationMixer(model);
          const clip = gltf.animations[0];
          //console.log(gltf);
          mixer.clipAction(clip).play();

          delta = clock.getDelta();
          mixer.tick = (delta) => mixer.update(delta);
          animate();
        });

        //this.map = map;

        // use the Mapbox GL JS map canvas for three.js
        renderer = new THREE.WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true,
        });

        function animate() {
          delta = clock.getDelta();
          mixer.update(delta);
          //model.tick = (delta) => mixer.update(delta);
          requestAnimationFrame(animate);
          renderer.render(scene, camera);
        }

        renderer.autoClear = false;
      },
      onRemove: function (map, gl) {
        // This function hasn't been tested and may cause problems when removing a 3D object
        // this.container.parentNode.removeChild(this.container);
        // this.map = undefined;
      },
      render: function (gl, matrix) {
        const rotationX = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(1, 0, 0),
          modelTransform.rotateX
        );
        const rotationY = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(0, 1, 0),
          modelTransform.rotateY
        );
        const rotationZ = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(0, 0, 1),
          modelTransform.rotateZ
        );

        const m = new THREE.Matrix4().fromArray(matrix);
        const l = new THREE.Matrix4()
          .makeTranslation(
            modelTransform.translateX,
            modelTransform.translateY,
            modelTransform.translateZ
          )
          .scale(
            new THREE.Vector3(
              modelTransform.scale * length_arr[0],
              modelTransform.scale * length_arr[1],
              modelTransform.scale * length_arr[2]
            )
          )
          .multiply(rotationX)
          .multiply(rotationY)
          .multiply(rotationZ);

        camera.projectionMatrix = m.multiply(l);
        renderer.resetState();
        renderer.render(scene, camera);
        map.current.triggerRepaint();
      },
    };

    return customLayer;
  }

  // =============== Polygons Animations ================

  // This function creates a single polygon (stored in db)
  //      'polygon' parameter is a link to the dataset/tileset created in MapBox Studio
  function addSinglePolygon(polygon) {
    //console.log("Add single polygon:");
    //console.log(polygon);
    // Update the source with this new data
    map.current.getSource("polygonSource").setData({
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [polygon],
      },
    });
  }

  // This function organizes/handles creating the polygons (stored in db) and creating the animation
  //      'polygons' parameter is a link to the dataset/tileset created in MapBox Studio
  function addPolygonAnimation(polygon1, polygon2) {
    // Create an array of consecutive polygons that will be used to create an animation
    const all_polygons_arr = createNewPolygons(polygon1, polygon2, 60);
    const polygons_length = all_polygons_arr.length;

    animatePolygon(all_polygons_arr, polygons_length);
  }

  // This function takes two arrays (starting_coords & ending_coords) of coordinates
  //      and builds a number of polygons in between, based on the number of steps
  // It returns an array of all the new polygons created
  function createNewPolygons(starting_coords, ending_coords, steps) {
    //console.log("STARTING COORDINATES");
    //console.log(starting_coords.length + " - " + ending_coords.length);
    if (starting_coords.length !== ending_coords.length) {
      //console.log("The number of coordinates doesn't match.");
      return [ending_coords];
    }

    var all_polygons = [starting_coords];
    var total_coords = starting_coords.length;

    // Loop through each step one by one
    //      Every step will create a new polygon between the starting & ending coordinates
    for (let step = 0; step < steps; step += 1) {
      var new_coords_array = []; // This will be used to create a new polygon
      var stepDistance = 0; // Distance away from the original point

      // Loop through each individual
      starting_coords.forEach(function (coord, index) {
        //console.log("starting coords[" + index + "] = " + coord);

        if (index < total_coords - 1) {
          const two_coords = [starting_coords[index], ending_coords[index]];
          //console.log("Coords: " + two_coords);

          // Calculate the distance in kilometers between route start/end point.
          const line = turf.lineString(two_coords);
          const lineDistance = turf.length(line);

          stepDistance = step * (lineDistance / steps);
          // Determine where along this line the new coordinate needs to placed based on what step you are on
          const segment = turf.along(line, stepDistance);
          new_coords_array.push(segment.geometry.coordinates);
        }
      });

      // Add the new polygon onto the all_polygons array
      all_polygons.push(new_coords_array);
    }

    all_polygons.push(ending_coords);
    //console.log("All Polygons arrays: ");
    //console.log(all_polygons);
    return all_polygons;
  }

  var animation_counter = 0;
  var forward_advance = true;
  var ap = [];
  function animatePolygon(all_polys, polygons_length) {
    ap = all_polys;

    if (forward_advance) {
      setTimeout(function () {
        animation_counter = animation_counter + 1;
        animatePolygon(all_polys, polygons_length);
        return;
      }, 100);
    } else {
      setTimeout(function () {
        animation_counter = animation_counter - 1;
        animatePolygon(all_polys, polygons_length);
        return;
      }, 100);
    }

    if (animation_counter >= polygons_length - 1) {
      return;
      forward_advance = false; // This creates a bouncing effect for the polygon when swapped before the return
    }
    if (animation_counter <= 0) {
      forward_advance = true;
    }

    // Update the source with this new data
    map.current.getSource("polygonSource").setData({
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [ap[animation_counter]],
      },
    });
  }

  // declare a function for changing the opacity after a zoom level is figured out
  function changeOpacity(z){
    var newOpacity = 0.8;
    if (z > 8){
      newOpacity = 0.8;
    }
    else if(z < 2){
      newOpacity = 0.2;
    }
    else {
      newOpacity = (-0.1 * z + 1.01).toFixed(2);
    }
    //console.log("Changing opacity to: " + newOpacity + " from zoom level: " + z);
    // Change the opacity of the country layers
    // map.current.setPaintProperty('countries-highlighted1', 'fill-opacity', newOpacity.toFixed(2));
    // map.current.setPaintProperty('countries-highlighted2', 'fill-opacity', newOpacity.toFixed(2));
    // map.current.setPaintProperty('countries-highlighted3', 'fill-opacity', newOpacity.toFixed(2));
    // map.current.setPaintProperty('countries-highlighted4', 'fill-opacity', newOpacity.toFixed(2));
    // map.current.setPaintProperty('countries-highlighted5', 'fill-opacity', newOpacity.toFixed(2));
  }

  // ==================== IMAGE OVERLAYS =====================
  function removeImageOverlay(){
      if (map.current.getLayer("image-overlay-layer")) {
          map.current.removeLayer("image-overlay-layer");
      }
  }

  function addImageOverlay(timeline_event){
      // If the overlay_id is equal to zero there is no map overlay for that timeline event/time
      if(timeline_event.overlay_id == 0 || timeline_event.overlay_id === undefined || timeline_event.overlay_id == ""){ return; }
      console.log("IMAGE OVERLAY: " + JSON.stringify(timeline_event.overlay_media))
      removeImageOverlay();


      const time_id = timeline_event.timeline_id;
      const source_name = 'image_overlay_source' + time_id;
      const overlay_media = timeline_event.overlay_media[0];
      console.log(overlay_media)
      const overlay_directory = "assets/" + overlay_media.image_directory;
      console.log(overlay_directory)
      const lng_tl = parseFloat(overlay_media.longitude_top_left)
      const lat_tl = parseFloat(overlay_media.latitude_top_left)
      const lng_tr = parseFloat(overlay_media.longitude_top_right)
      const lat_tr = parseFloat(overlay_media.latitude_top_right)
      const lng_br = parseFloat(overlay_media.longitude_bottom_right)
      const lat_br = parseFloat(overlay_media.latitude_bottom_right)
      const lng_bl = parseFloat(overlay_media.longitude_bottom_left)
      const lat_bl = parseFloat(overlay_media.latitude_bottom_left)
      if (!map.current.getSource(source_name)) {
        map.current.addSource(source_name, {
            'type': 'image',
            'url': overlay_directory,
            'coordinates': [
                [lng_tl, lat_tl],
                [lng_tr, lat_tr],
                [lng_br, lat_br],
                [lng_bl, lat_bl]                
            ]
        });
      }
      map.current.addLayer({
          id: 'image-overlay-layer',
          'type': 'raster',
          'source': source_name
      });
  }


  // declare various easing functions. easing functions mathematically describe how fast a value changes during an animation.
  // 		each function takes a parameter t that represents the progress of the animation.
  //		t is in a range of 0 to 1 where 0 is the initial state and 1 is the completed state.
  var easingFunctions = {
    linear: function (t) {
      return t;
    },
    quad: function (t) {
      return t * t;
    },
    // start slow and gradually increase speed
    log: function (t) {
      return 1.1 * Math.log2(t) + 0.2;
    },
    // start slow and gradually increase speed
    easeInCubic: function (t) {
      return t * t * t;
    },
    // start fast with a long, slow wind-down
    easeOutQuint: function (t) {
      return 1 - Math.pow(1 - t, 5);
    },
    // slow start and finish with fast middle
    easeInOutCirc: function (t) {
      return t < 0.5
        ? (1 - Math.sqrt(1 - Math.pow(2 * t, 2))) / 2
        : (Math.sqrt(1 - Math.pow(-2 * t + 2, 2)) + 1) / 2;
    },
    // fast start with a "bounce" at the end
    easeOutBounce: function (t) {
      var n1 = 7.5625;
      var d1 = 2.75;

      if (t < 1 / d1) {
        return n1 * t * t;
      } else if (t < 2 / d1) {
        return n1 * (t -= 1.5 / d1) * t + 0.75;
      } else if (t < 2.5 / d1) {
        return n1 * (t -= 2.25 / d1) * t + 0.9375;
      } else {
        return n1 * (t -= 2.625 / d1) * t + 0.984375;
      }
    },
  };

  //END OF MAP FUNCTIONALITY IMPLEMENTATION

  // For scrollama implementation
  const onStepEnter = ({ data }) => {
    //console.log("onStepEnter data: " + data + " - timeline_data: " + timeline_data);
    if (data && timeline_data) {
      //console.log("on step entered with data: " + data + " ("+typeof(data)+")");
      //console.log("Current step: " + currentStep);
      if (data !== currentStep) {
        // Change the active dot
        //console.log("Changing active dot-t");
        document.getElementById(data + "-dot-t").classList.add("active");
        if (document.getElementById(currentStep + "-dot-t") !== null) {
          document
            .getElementById(currentStep + "-dot-t")
            .classList.remove("active");
        }

        setCurrentStep(data); //setCurrentStep(steps.indexOf(data));
        
        clearMapCountryColours(); 
        //console.log("toggleableLayerIds onstepenter: " + toggleableLayerIds);
        clearMapAnimations();
        removeImageOverlay();

        timeline_data.timeline.map((time) => {
          if (time.timeline_id == data){
              //console.log("onStepEnter - timeline_id == data");
              if(mapLayersLoaded){
                //console.log("Map animation xxxxxxxxxxxx: " + JSON.stringify(time));
                var bbox_arr = time.bounding_box_arr;
                if (bbox_arr != "" && bbox_arr != null && bbox_arr != 'null'){
                    // If we have a bounding box 
                    var map_options = { 
                                        easing: easingFunctions["linear"],
                                        duration: 4000,
                                        bearing: 0, //time.bearing, 
                                        pitch: 0 //time.pitch  
                                    };
                    setBoundingBox(bbox_arr, map_options);
                }
                else{
                    //changeOpacity(time.zoom);
                    flyToLocation(time.latitude, time.longitude, time.zoom, time.pitch, time.bearing);
                }
                changeCountryColours(time.countries_json);
                loadMapAnimations(time);
                addImageOverlay(time);
              }
          }
        });
      }   
    }
  };

  const filterTimeline = (filterValue) => {
    setTimelineFilter(filterValue);

    //console.log("Now filtering timeline ========================= " + filterValue);
    if(filterValue == 0){
      var new_times = [];
      timeline_data.timeline.map((time) => {
        new_times.push(time);
      });
      setFilteredTimes(new_times);
    }
    else if (filterValue == 1 || filterValue == 2 || filterValue == 3) {
      // Narrative Priority = 1, 2 or 3
      //    2 also includes all narrative_priority of 1
      //    3 also includes all narrative_priority of 1 & 2
      var new_times = [];
      timeline_data.timeline.map((time) => {
        const np = Number(time.narrative_priority);
        //console.log (np);
        if (np <= filterValue && np != 0) {
          //console.log("Push time on to new_time")
          new_times.push(time);
        }
      });
      setFilteredTimes(new_times);
    }
    else if(filterValue == 4){
      // Canadian content 
      var new_times = [];
      timeline_data.timeline.map((time) => {
        if (time.canadian_specific_content == 1 || time.canadian_specific_content == 'yes') {
          new_times.push(time);
        }
      });
      setFilteredTimes(new_times);
    }
    else{
      var new_times = [];
      timeline_data.timeline.map((time) => {
        new_times.push(time);
      });
      setFilteredTimes(new_times);
    }
  };


  //Audio Player visibility function
  const [isContentVisible, setContentVisibility] = useState(false);
  const toggleContent = () => {
    setContentVisibility(!isContentVisible);
  };


  return (
    <div>
      <TopNav onClick={handleClick} />

      <div className="flexbox-container">
        {/* <div className='flexbox-item flexbox-item-1'>
					<nav className='stop-nav-items'> 
						<div className="smenu-icon">
							<i class="fa-solid fa-bars"></i>
						</div>
						<div>
							<p className="comp-name">  Timeline</p>
							<hr className='hrline' />
						</div>
                        <div>
                            <ul>
                                <li
                                    onClick={() => filterTimeline(1)}
                                    className='side-link'
                                >
                                    <i class='fa-solid fa-gear'></i> Top Priority
                                </li>
                                <li
                                    onClick={() => filterTimeline(2)}
                                    className='side-link'
                                >
                                    <i class='fa-solid fa-gear'></i> Medium Priority
                                </li>
                                <li
                                    onClick={() => filterTimeline(3)}
                                    className='side-link'
                                >
                                    <i class='fa-solid fa-gear'></i> Low Priority
                                </li>
                                <li
                                    onClick={() => filterTimeline(4)}
                                    className='side-link'
                                >
                                    <i class='fa-solid fa-gear'></i> Canadian Content
                                </li>
                            </ul>
                        </div>
					</nav>
				</div> */}
        <Menu>
          <nav className="stop-nav-items ">
            <Link to="/" style={{ textDecoration: "none" }}>
              <i class=" fa-solid fa-house"></i>
            </Link>
            <div>
              <p className="comp-name">   {tCommon("navigation.timeline")}</p>
              <hr className="hrline" />
            </div>
            <ul style={{ listStyleType: "none" }}>
              <li
                onClick={() => filterTimeline(1)}
                className="side-link menu-item"
              >
                <i class="fa-solid fa-gear"></i> {tCommon("navigation.timelineSubOne")}
              </li>
              <li
                onClick={() => filterTimeline(2)}
                className="side-link menu-item"
              >
                <i class="menu-item fa-solid fa-gear"></i> {tCommon("navigation.timelineSubTwo")}
              </li>
              <li
                onClick={() => filterTimeline(3)}
                className="side-link menu-item"
              >
                <i class="menu-item fa-solid fa-gear"></i>  {tCommon("navigation.timelineSubThree")}
              </li>
              <li
                onClick={() => filterTimeline(4)}
                className="side-link menu-item"
              >
                <i class="menu-item fa-solid fa-gear"></i>  {tCommon("navigation.timelineSubFour")}
              </li>
              <li
                onClick={() => filterTimeline(0)}
                className="side-link menu-item"
              >
                <i class="menu-item fa-solid fa-gear"></i> {tCommon("navigation.timelineSubFive")}
              </li>
            </ul>
          </nav>
        </Menu>
        <div className="flexbox-item flexbox-item-2">
          <div ref={mapContainer} className="map-container" />

          <nav className="landing-navbar-t">
            <div className="dots-scroll">
            <ul >
              {newTimes.map((time) => {
                return [
                  <li>
                      <Scrollchor className="nav-link dot-t" id={time.timeline_id + "-dot-t"} data-scroll={time.timeline_id} to={"#" + time.timeline_id}>
                        <span> {time.start_date} </span>
                      </Scrollchor>
                    
                    {/* <NavLink className="dot-t" id={time.timeline_id + "-dot-t"} data-scroll={time.timeline_id} to={"#" + time.timeline_id} state={state}>
                      <span> {time.start_date} </span>
                    </NavLink> */}
                    {/* {<a
                      id={time.timeline_id + "-dot-t"}
                      href={"#" + time.timeline_id}
                      className="dot-t"
                      data-scroll={time.timeline_id}
                    >
                      <span> {time.start_date} </span>
                    </a>} */}
                  </li>,
                ];
              })}
            </ul>
            </div>
          </nav>
          {/* <i class="fa-solid fa-arrow-down"></i> */}

          <div className="dots-background">

          </div>

          <div className="timeline-card-container">
            <div className="timeline-section">
              
              {newTimes.map((time) => {

                //to handle for when an image is unavailable
                const hasImage = time.media && time.media[0] && time.media[0].directory;
                //console.log("Time media title: " + JSON.stringify(time.media[0]));
                //const hasImageInfo = time.media[0].title && time.media[0].subtitle && time.media[0].credit;
                // var hasImageInfo = true;
                // if(typeof time.media[0].title !== "undefined" && typeof time.media[0].subtitle !== "undefined" && typeof time.media[0].credit !== "undefined") {
                //   hasImageInfo = false;
                // }
                //timeline_data.timeline.map((time) => {
                return [
                  
                  <div className="scrollama-container">
                  
                    <Scrollama
                      onStepEnter={onStepEnter}
                      //onStepExit={onStepExit}
                      progress
                      //onStepProgress={onStepProgress}
                      offset= "250px"
                      debug={false}
                    >
          
                      <Step data={time.timeline_id} key={time.timeline_id}>
                        <section id={time.timeline_id} className="sec-t" >
                          <div className="time-card js-navDots" >
                            <h3 className="text-card-date"> {time.start_date}</h3>
                            <h2 className="heading-card"> { time.title ? time.title : null }</h2>
                            <div className="h-image-container">

                              { hasImage ? (
                              <img
                                className="time-card-image"
                                src={ 
                                  "/assets/" + time.media[0].directory //images/UNEF-IMAGES/" + time.media[0].name
                                }
                              />

                              

                              ) : (
                                <p></p>
                                // <p>No image available for this time</p> 
                                // or use placeholder image
                              )}

                              <div className="hover-text">
                
                                <p className='title-img-text'> { hasImage ? (time.media[0].title) : null } </p>
                                <p className='img-text'>{ hasImage ? (time.media[0].subtitle) : null } </p>
                                <p className='img-source'>Credit: { hasImage ? (time.media[0].credit) : null } </p>
                              </div>
                            </div>
                  <div className="audio-section">
                    <i class="fa-solid fa-volume-high audio-icon" onClick={toggleContent}></i>
           
                    {/*isContentVisible && (
                      <span className="opened-span">
                        
                        {/* <ReactAudioPlayer src="/audio-test.mp3" controls className='audio-player' /> }
                      
                      <p className='audio-section'>Audio currently unavailable</p>
                      </span>
                    )*/}
                  </div>
                            <div>
                              <p className="text-card-subtitle">
                                { HtmlReactParser(time.subtitle) }
                              </p>
                              <p className="text-card-description">
                                { HtmlReactParser(time.description) }
                              </p>
                              <p className="text-card-id">
                                <br />
                                {time.timeline_id}
                              </p>
                            </div>
                          </div>
                        </section>
                      </Step>
                    </Scrollama>
                  </div>,
                ];
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};  

export default injectSheet(styles)(Map3DScrollama);
