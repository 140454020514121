import { useState } from 'react';
import React from 'react';
import { useCollapse } from 'react-collapsed';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function TimelineActivity3({ title, children }) {


    const {t} = useTranslation("studentActivities")
        
             const textFormat = (text) =>
                text.split('\n').map((line, index) => (
            
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ));

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  // These variables are for the props to be passed between pages
  const location = useLocation();
  console.log ("Timeline3 useLocation: " + JSON.stringify(location));
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(state.language);

  return (


    <div>


      <div className='activity-sec' >
                <h2  className='activity-title'>
                {t("timeline.three.title")}
                </h2>
                <div className='activity-content'>
                    
                
                <p className='mission-text'>  {t("timeline.three.subTitle")}</p>

                <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("timeline.three.collapse") : t("timeline.three.expand")}</button>
      <h2>{title}</h2>

      <div {...getCollapseProps()}>{isExpanded && children}
                

                <h3 className='outline-head'> {t("timeline.three.objective.title")}</h3>
                <p className='outline-text'>  {textFormat(t("timeline.three.objective.description"))}
                
                 <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'> 
                 
                  {t("timeline.three.objective.link")}
                  
                   </a> 
                   {textFormat(t("timeline.three.objective.descriptionContinued"))}
                  
                </p>

                <h3 className='outline-head'>  {t("timeline.three.actionSteps.title")} </h3>
                <p className='outline-text'> 
                    <Link to="/timeline" state={{mission_id:missionID, language:language, timeline_filter:1}}> {t("timeline.three.actionSteps.link")}</Link> {t("timeline.three.actionSteps.description")}
                 {textFormat(t("timeline.three.actionSteps.descriptionContinued"))}
                </p>

                <h3 className='outline-head'> {t("timeline.three.questions.title")}</h3>
                <p className='outline-text'> 
                {textFormat(t('timeline.three.questions.description'))} 
                  

                </p>


                <h3 className='outline-head'> {t("timeline.three.debrief.title")}</h3>
                <p className='outline-text'> 


                {t("timeline.three.debrief.description")} <Link to="/mission-control" state={{mission_id:missionID, language:language}}>{t("timeline.three.debrief.link1")}</Link>. <br /> 
                   
                   
                   
                {textFormat(t("timeline.three.debrief.descriptionContinued"))}
                  
                    <ul>
                        <li> <Link to="/mission-control" state={{mission_id:missionID, language:language}}>  {t("timeline.three.debrief.link2")}</Link></li>
                        <li> <Link to="/mission-control" state={{mission_id:missionID, language:language}}>   {t("timeline.three.debrief.link3")}</Link></li>
                    </ul>

                </p>
                
                </div>
                </div>

            </div>
    </div>


  );
}

export default TimelineActivity3;