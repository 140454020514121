import React from "react";
import './Contact.css';
import LandingNav from '../Navigation/LandingNav';
import Footer from '../Navigation/Footer';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
    
function Contact(){

    const {t,i18n} = useTranslation("contact")
    
    function handleClick(newLang) {
    
    }
    
    return(

        <div>

            <LandingNav onClick={handleClick} />
            {/* <LandingNav onClick={handleClick} state={{language: language}} /> */}

             {/* Contact Us Section */}


             <div className='contact-container'>

                <div className='contact-heading'>

                    <h2 className='contact-title'>    {t("contact.title")} </h2>
                    <p className='contact-subtitle'>{t("contact.subTitle")}</p>

                </div>

                <div className='contact-section'>

                    <div className='contact-left'>

                        <h2 className='contact-info'> {t("contact.infoTitle")}</h2>
                        <p className='contact-info-subtitle'> {t("contact.infoSubtitle")}</p>

                        <p className="contact-info-text">
                        <span className="contact-info-span">
                            
                            <img src="/bxs_phone-call.png"  alt=""/> 
                            
                            </span>

                             {t("contact.number")}
                         </p>


                        <p className="contact-info-text">
                        <span  className="contact-info-span">  
                        
                        <img  src="/ic_sharp-email.png" alt=""/>  
                        
                        </span>
{t("contact.email")}
                         </p>

                         <p className="contact-info-text">
                         <span className="contact-info-span">
                            
                             <img src="/carbon_location-filled.png" alt=""/>
                            
                             </span>

                          {t("contact.address")}
                       </p>

                    </div>





                    <div className='contact-right'>

                        <div className='contact-right-row'>

                            <div className='contact-col-1'>
                                <div class="form-outline">
                                  <label class="form-label" for="typeText"> {t("contact.formName")}</label>
                                  <input type="text" id="typeText" class="form-control" />
                   
                                    </div>
                            </div>



                            <div className='contact-col-2'>
                            <div class="form-outline">

                            <label class="form-label" for="typeEmail">{t("contact.formEmail")}</label>
                            <input type="email" id="typeEmail" class="form-control" />

                            </div>
                            </div>

                        </div>


                        <div class="form-outline">
                            <label class="form-label" for="textAreaExample"> {t("contact.formMessage")}</label>
                            <textarea class="form-control" id="textAreaExample" rows="5"></textarea>
                            
                        </div>


                        <NavLink to={"#"} >
                         <button className="contact-button"> {t("contact.buttonText")}</button>
                        </NavLink>
                        
                        </div>


                </div>




             </div>











            <Footer/>

        </div>


    )







}

export default Contact;
  