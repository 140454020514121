import React, {useState} from "react";
import LandingSidebar from '../Navigation/LandingSidebar'
import LandingNav from '../Navigation/LandingNav';
import { useLocation } from 'react-router-dom';
function Documents()   {
	
  // const location = useLocation();
  // const { state } = location;
  // const [language, setLanguage] = useState(state.language);
  function handleClick(newLang) {
    
  }

  return (
    <div>
      <LandingNav onClick={handleClick} />
      {/* <LandingNav onClick={handleClick} state={{language: language}} /> */}
	
		<LandingSidebar />
		</div>
	)
};

export default Documents;
