import React from 'react';
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';

function Alliances({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  
   const {t} = useTranslation("studentActivities")


   
                      const textFormat = (text) =>
                         text.split('\n').map((line, index) => (
                     
                           <React.Fragment key={index}>
                             {line}
                             <br />
                           </React.Fragment>
                         ));

  return (
   
    <div>
      <div className='activity-sec'>
				<h2  className='activity-title'>
                  {t("alliances.title")}
				</h2>


                <div className='activity-content'>

                <p className='mission-text'>  {t("alliances.subTitle")} </p>
                

                <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("alliances.collapse") : t("alliances.expand")}</button>
          <h2>{title}</h2>

          <div {...getCollapseProps()}>{isExpanded && children}
            
                   
                
               

                <h3 className='outline-head'> {t("alliances.objective.title")}</h3>
                <p className='outline-text'>  {t("alliances.objective.descriptionOne")} </p>

                <p className='outline-big6'>
                 <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'> {t("alliances.objective.link")}</a><br />
                 {t("alliances.objective.descriptionContinued")} 
                </p>

                <h3 className='outline-head'> {t("alliances.actionSteps.title")}</h3>
                <p className='outline-text'> {textFormat(t("alliances.actionSteps.descriptionOne"))}
                 
                {t("alliances.actionSteps.descriptionContinued")}
                 <a href='https://www.worlddata.info/alliances/index.php' target='_blank'> {t("alliances.actionSteps.link")}</a>
                 
                 {textFormat(t("alliances.actionSteps.descriptionTwo"))}
                 

                </p>

                <h3 className='outline-head'>  {t("alliances.questions.title")} </h3>
                <p className='outline-text'> {textFormat(t("alliances.questions.description"))} 
                </p>


                <h3 className='outline-head'>  {t("alliances.debrief.title")}</h3>
                <p className='outline-text'> </p>
                </div>
                </div>
			</div>
    </div>

  );
}

export default Alliances;