import React from "react";
import './TeacherResources.css';
import LandingNav from '../Navigation/LandingNav';
import Footer from '../Navigation/Footer';
import { NavLink, useLocation } from 'react-router-dom';
import Accordion from '../Accordion/Accordion';
import { useTranslation } from 'react-i18next';
    
function TeacherResources(){

  const {t,i18n} = useTranslation("teacherResources")

  function handleClick(newLang) {
    
  }
  
 // Dynamically fetch the sections array in order to format the text (bold titles)
 const sections = t('overview.contentTwo.sections', { returnObjects: true });
 const activities = t('overview.contentFour.activities', { returnObjects: true });


  const textFormat = (text) =>
    text.split('\n').map((line, index) => (

      <React.Fragment key={index}>

        {line}
        <br />
      </React.Fragment>
    ));

    return(

        <div>

          <LandingNav onClick={handleClick} />
          {/* <LandingNav onClick={handleClick} state={{language: language}} /> */}

            {/* Teacher Resources Header Section */}
            
            <div className='teacher-header'>
                    <img
                    loading="lazy"
                    src='/teacher-header-image.jpg'
                    className='teacher-header-image'
                    />
            <div className='teacher-header-content'>

            <h2 className='teacher-header-heading'> 
            
            {t("header.title")}

            </h2>

            <p className='teacher-header-text'>

            {t("header.subTitle")}

            </p>

            </div>

            </div>




            {/* Teacher Project Overview Section */}

            <div className='teachers-faq-section'>
<h2 className='about-faq-heading'>
  {t("overview.title")}
</h2>

<Accordion allowMultipleOpen>
        <div label= {t("overview.contentOne.label")} isOpen>
          <p>

          {textFormat(t("overview.contentOne.descriptionOne"))}
           
          </p>
          
          <img
            className="acc-img"
            src='acc-img1.jpg'
            alt=""
            />

          {textFormat(t("overview.contentOne.descriptionTwo"))}

         
        </div>


        <div label= {t("overview.contentTwo.label")}>
          

          {textFormat(t("overview.contentTwo.descriptionOne"))}

          <ul>
          {sections &&
            sections.map((section, index) => (
              <li key={index}>
                <strong>{section.title}</strong> - {section.description}
              </li>
            ))}
        </ul>
         
        </div>

        <div label= {t("overview.contentThree.label")}>

        {textFormat(t("overview.contentThree.descriptionOne"))}
            
          <br/>
          
        </div>

        <div label= {t("overview.contentFour.label")}>

        {textFormat(t("overview.contentFour.descriptionOne"))}
          

        <ul>
          {activities &&
            activities.map((activity, index) => (
              <li key={index}>
                {activity}
              </li>
            ))}
        </ul>
        
        </div>

       
      </Accordion>

</div>

             {/* Teacher FAQ Section */}

             <div className='about-faq-section'>
<h2 className='about-faq-heading'>

 {t("faq.title")}

</h2>

<Accordion allowMultipleOpen>
        


        <div label= {t("faq.contentOne.label")}>

          <p>

          {textFormat(t("faq.contentOne.description"))}

          </p>
            
        </div>

        <div label= {t("faq.contentTwo.label")}>

        <p>

         {textFormat(t("faq.contentTwo.description"))}

           </p>
        
        
        </div>

        <div label= {t("faq.contentThree.label")}>


        <p>
 
            {textFormat(t("faq.contentThree.description"))}

        </p>

         
        </div>

        <div label= {t("faq.contentFour.label")}>
            <p>
            {textFormat(t("faq.contentFour.description"))}
          </p>
          
          
          <p>
            <ol>
              <li> {t("faq.contentFour.itemOne")}</li>
              <li> {t("faq.contentFour.itemTwo")}</li>
              <li> {t("faq.contentFour.itemThree")}</li>
            </ol>
          </p>
          <p>
          {t("faq.contentFour.itemFour")}
            <a href="https://peacekeeping.un.org/en/principles-of-peacekeeping" target="_blank">  {t("faq.contentFour.link")}</a> 
          </p>
        </div>

        {/* <div label='Question 5'>
            <p>
          Usionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, probo 
          eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi.\
           Fugit expetenda interesset no, probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan 
           noluisse, nostrum accumsan movet salutandi. Fugit expetenda 
          
          </p>
          <br/>
          <p>Usionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda interesset no, 
            probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum accumsan noluisse, nostrum accumsan movet 
            salutandi. Fugit expetenda interesset no, probo eloquentiam mei eu. Et ullum iisque conclusionemque eam, mel ad erat cum 
            accumsan noluisse, nostrum accumsan movet salutandi. Fugit expetenda usionemque eam, mel ad erat cum </p>
        </div>  */}
      </Accordion>

</div>

            
            
            
            
            
            
            
            
            
            
            <Footer/>


        </div>




    )






}

export default TeacherResources;
  