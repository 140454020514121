import React from 'react';
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';

function VeteranInterviews({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  
     const {t} = useTranslation("studentActivities")
  
  
     
                        const textFormat = (text) =>
                           text.split('\n').map((line, index) => (
                       
                             <React.Fragment key={index}>
                               {line}
                               <br />
                             </React.Fragment>
                           ));

  return (
   
    <div>
    <div className='activity-sec'>
      <h2  className='activity-title'>
          {t("veteranInterviews.title")}
      </h2>


              <div className='activity-content'>

              <p className='mission-text'> {t("veteranInterviews.subTitle")} </p>
              

            
              <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("veteranInterviews.collapse") : t("veteranInterviews.expand")}</button>
          <h2>{title}</h2>

          <div {...getCollapseProps()}>{isExpanded && children}
            
              
             
          <h3 className='outline-head'> {t("veteranInterviews.objective.title")}</h3>
                <p className='outline-text'>  {t("veteranInterviews.objective.description")} </p>

                <p className='outline-big6'>
                 <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'> {t("veteranInterviews.objective.link")}</a>
                 {textFormat(t("veteranInterviews.objective.descriptionContinued"))} 
                </p>


                <h3 className='outline-head'> {t("veteranInterviews.actionSteps.title")}</h3>
                <p className='outline-text'>  {textFormat(t("veteranInterviews.actionSteps.description"))} </p>

             
             
                <h3 className='outline-head'> {t("veteranInterviews.questions.title")}</h3>
                <p className='outline-text'>  {textFormat(t("veteranInterviews.questions.description"))} </p>

            


                <h3 className='outline-head'> {t("veteranInterviews.debrief.title")}</h3>
                <p className='outline-text'>  {textFormat(t("veteranInterviews.debrief.description"))} </p>

              </div>
              </div>
    </div>
  </div>

  );
}

export default VeteranInterviews;