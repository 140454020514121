import "./LandingPage.css";
import $ from "jquery";
import React, { useRef, useEffect, useState } from "react";
import { Scrollama, Step } from "react-scrollama";
import mapboxgl from "mapbox-gl";
import { Link, useLocation } from "react-router-dom";
import LandingNav from "../Navigation/LandingNav";
import { useTranslation } from "react-i18next";

export var map;
const chapters = {
  unef1: {
    bearing: 90,
    center: [30.825839, 30.297794],
    zoom: 3,
    country: ["EG"],
  },
  onuc: {
    bearing: 27,
    center: [25, -4],
    bearing: 150,
    zoom: 3,
    pitch: 20,
    country: ["CD"],
  },
  unprofor: {
    bearing: 90,
    center: [22, 41],
    zoom: 4,
    speed: 0.6,
    pitch: 20,
    country: ["BA", "HR", "ME", "MK", "RS", "SI", "XK"],
  },
  "defense-of-canada": {
    bearing: 27,
    center: [-93, 56],
    zoom: 3,
    country: ["CA"],
  },
  unficyp: {
    bearing: 45,
    center: [33.6, 35],
    zoom: 5,
    pitch: 20,
    speed: 0.5,
    country: ["CY"],
  },
  vietnam: {
    bearing: 180,
    center: [107, 13],
    zoom: 4,
    country: ["VN"],
  },
  "defense-of-germany": {
    bearing: 90,
    center: [9.6, 50],
    zoom: 4,
    pitch: 40,
    country: ["DE"],
  },
  afghanistan: {
    bearing: 90,
    center: [67, 33],
    zoom: 4,
    pitch: 20,
    country: ["AF"],
  },
};
//mapboxgl.accessToken = 'pk.eyJ1IjoicmFqYWNoYW5kcmEiLCJhIjoiY2xmaW15OTJxMG9vcjN6cXBtYTJwZ2NhMCJ9.1IGRU_sx_V05L3XCBNpSdQ';
mapboxgl.accessToken =
  "pk.eyJ1IjoibGZjaG9ja2V5IiwiYSI6ImNqdHQ3OTJzYTAzbDM0NG9hcDJhcHM3YXgifQ.-xAMcIMztR--wvn0P57RnQ";

//let activeChapterName = 'unef1';
function LandingPage() {
  const { t, i18n } = useTranslation("missions");

  const [currentStep, setCurrentStep] = useState(1);
  const [activeChapterName, setActiveChapterName] = useState("unef1");
  const steps = ["summary", "cause", "setting"];

  const location = useLocation();
  const { state } = location;
  //console.log("LandinPage state: " + JSON.stringify(state));
  const [language, setLanguage] = useState(i18n.language); //useState(state.language);
  function handleClick(newLang) {
    // **** Will need to handle click to determine the content that shows up in the getData
  }

  //BEGINING OF MAP FUNCTIONALITY IMPLEMENTATION
  const [mapLayersLoaded, setMapLayersBool] = useState(false);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [data, setData] = useState({});

  const getData = () => {
    fetch("../mission_control.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        //console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        //console.log(myJson);
        setData(myJson);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // Initialize the map
  //      Also, add all of the layers we will use
  useEffect(() => {
    //console.log("Map use Effect initiated");
    if (map.current) return; // initialize map only once
    setMapLayersBool(false);
    //console.log("Map layers bool: false");
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/lfchockey/clid5nak2002p01p6hd1f9kb4", //'mapbox://styles/mapbox/satellite-streets-v12', //mapbox://styles/mapbox/satellite-v9',
      center: [30.825839, 30.297794],
      zoom: 2,
      projection: "mercator",
    });

    // When the map loads, add the sources and layers to use for polygons, country colours, arrows, etc.
    map.current.on("load", () => {
      const layers = map.current.getStyle().layers;
      // Find the index of the first symbol layer in the map style.
      //    The highlighted-countries layer will be placed below this one in the map layer stack
      let firstSymbolId;
      for (const layer of layers) {
        //console.log(layer.type + " - " + layer.id);
        if (layer.type === "symbol") {
          firstSymbolId = layer.id;
          break;
        }
      }

      // Add source for country polygons using the Mapbox Countries tileset
      // The polygons contain an ISO 3166 alpha-3 code which can be used to for joining the data
      // https://docs.mapbox.com/vector-tiles/reference/mapbox-countries-v1
      map.current.addSource("countries", {
        type: "vector",
        url: "mapbox://mapbox.country-boundaries-v1",
      });

      // Create the layer for colouring in a selected country
      // Add filled county polygons for highlighted display.
      map.current.addLayer(
        {
          id: "countries-highlighted1", //United Nations
          type: "fill",
          source: "countries",
          "source-layer": "country_boundaries",
          paint: {
            "fill-outline-color": "#484896",
            "fill-color": "#44bef1",
            "fill-opacity": 0.9,
          },
          // Display none by adding a filter with an empty string.
          filter: ["in", "iso_3166_1", ""],
        },
        firstSymbolId
      );
      map.current.addLayer(
        {
          id: "countries-highlighted2", // Main Country
          type: "fill",
          source: "countries",
          "source-layer": "country_boundaries",
          paint: {
            "fill-outline-color": "#484896",
            "fill-color": "#b18f06",
            "fill-opacity": 0.9,
          },
          // Display none by adding a filter with an empty string.
          filter: ["in", "iso_3166_1", ""],
        },
        firstSymbolId
      );
      map.current.addLayer(
        {
          id: "countries-highlighted3", // Positive Alliance
          type: "fill",
          source: "countries",
          "source-layer": "country_boundaries",
          paint: {
            "fill-outline-color": "#484896",
            "fill-color": "#0623b1",
            "fill-opacity": 0.9,
          },
          // Display none by adding a filter with an empty string.
          filter: ["in", "iso_3166_1", "EG"],
        },
        firstSymbolId
      );
      map.current.addLayer(
        {
          id: "countries-highlighted4", // Negative Alliance
          type: "fill",
          source: "countries",
          "source-layer": "country_boundaries",
          paint: {
            "fill-outline-color": "#484896",
            "fill-color": "#eb0f0f",
            "fill-opacity": 0.9,
          },
          // Display none by adding a filter with an empty string.
          filter: ["in", "iso_3166_1", ""],
        },
        firstSymbolId
      );
      map.current.addLayer(
        {
          id: "countries-highlighted5", // Additional Alliance
          type: "fill",
          source: "countries",
          "source-layer": "country_boundaries",
          paint: {
            "fill-outline-color": "#484896",
            "fill-color": "#1c8112",
            "fill-opacity": 0.9,
          },
          // Display none by adding a filter with an empty string.
          filter: ["in", "iso_3166_1", ""],
        },
        firstSymbolId
      );

      // Create the Layers for polygons
      // Create the map source and layers for the polygons
      // Add a data source containing GeoJSON data.
      map.current.addSource("polygonSource", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [[]],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      map.current.addLayer({
        id: "polygonLayer",
        type: "fill",
        source: "polygonSource", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#0080ff", // blue color fill
          "fill-opacity": 0.5,
        },
      });
    });

    map.current.on("idle", function () {
      setMapLayersBool(true);
      //console.log("Map layers bool: true");
    });
  });

  //   // If the map moves, set the Lng, Lat, Zoom
  //   useEffect(() => {
  //     if (!map.current) return; // wait for map to initialize
  //     map.current.on('move', () => {
  //       setLng(map.current.getCenter().lng.toFixed(4));
  //       setLat(map.current.getCenter().lat.toFixed(4));
  //       setZoom(map.current.getZoom().toFixed(2));
  //     });

  //   });

  // declare various easing functions. easing functions mathematically describe how fast a value changes during an animation.
  // each function takes a parameter t that represents the progress of the animation. t is in a range of 0 to 1 where 0 is the initial state and 1 is the completed state.
  var easingFunctions = {
    linear: function (t) {
      return t;
    },
    quad: function (t) {
      return t * t;
    },
    // start slow and gradually increase speed
    log: function (t) {
      return 1.1 * Math.log2(t) + 0.2;
    },
    // start slow and gradually increase speed
    easeInCubic: function (t) {
      return t * t * t;
    },
    // start fast with a long, slow wind-down
    easeOutQuint: function (t) {
      return 1 - Math.pow(1 - t, 5);
    },
    // slow start and finish with fast middle
    easeInOutCirc: function (t) {
      return t < 0.5
        ? (1 - Math.sqrt(1 - Math.pow(2 * t, 2))) / 2
        : (Math.sqrt(1 - Math.pow(-2 * t + 2, 2)) + 1) / 2;
    },
    // fast start with a "bounce" at the end
    easeOutBounce: function (t) {
      var n1 = 7.5625;
      var d1 = 2.75;

      if (t < 1 / d1) {
        return n1 * t * t;
      } else if (t < 2 / d1) {
        return n1 * (t -= 1.5 / d1) * t + 0.75;
      } else if (t < 2.5 / d1) {
        return n1 * (t -= 2.25 / d1) * t + 0.9375;
      } else {
        return n1 * (t -= 2.625 / d1) * t + 0.984375;
      }
    },
  };

  function clearMapCountryColours() {
    if (mapLayersLoaded) {
      map.current.setFilter("countries-highlighted1", ["in", "iso_3166_1", ""]);
      map.current.setFilter("countries-highlighted2", ["in", "iso_3166_1", ""]);
      map.current.setFilter("countries-highlighted3", ["in", "iso_3166_1", ""]);
      map.current.setFilter("countries-highlighted4", ["in", "iso_3166_1", ""]);
      map.current.setFilter("countries-highlighted5", ["in", "iso_3166_1", ""]);
    }
  }

  //   function zoomMap(lvl){
  //       map.current.setZoom(lvl);
  //   }

  //   function setBoundingBox(bbox){
  //       console.log(bbox);
  //       var sw = new mapboxgl.LngLat(bbox[0].replace('[',''), bbox[1]);
  //       var ne = new mapboxgl.LngLat(bbox[2], bbox[3].replace(']',''));
  //       var llb = new mapboxgl.LngLatBounds(sw, ne);
  //       map.current.fitBounds(llb, { padding: 5 });

  //   }

  function flyToLocation(mission) {
    const mapValues = chapters[mission];
    const centre = mapValues.center;
    const zoom = mapValues.zoom;
    var animationOptions = {
      center: centre,
      duration: 2500,
      easing: easingFunctions["linear"],
      offset: [0, 0],
      bearing: 0,
      zoom: zoom,
      pitch: 0,
      animate: true,
      essential: true, // animation will happen even if user has `prefers-reduced-motion` setting on
    };

    map.current.flyTo(animationOptions);
  }

  // Accepts an array of iso2 (countries_arr) and the colour as a string id
  function changeActiveCountryColour(info) {
    if (mapLayersLoaded) {
      let colour = "positiveBlue";
      let countries_arr = chapters[info].country;
      //console.log("inside changeActiveCountryColour country: " + countries_arr);

      // Create the options for the filter
      var options = ["in", "iso_3166_1"];

      if (countries_arr.length > 0) {
        // Push the names of the countries to be filtered onto the end of the array
        countries_arr.forEach((c) => {
          options.push(c);
        });
      }

      // This filters the appropriate alliances based on the colour found
      if (colour === "UNBlue") {
        map.current.setFilter("countries-highlighted1", options);
      } else if (colour === "mainGold") {
        map.current.setFilter("countries-highlighted2", options);
      } else if (colour === "positiveBlue") {
        map.current.setFilter("countries-highlighted3", options);
      } else if (colour === "negativeRed") {
        map.current.setFilter("countries-highlighted4", options);
      } else if (colour === "otherGreen") {
        map.current.setFilter("countries-highlighted5", options);
      } else {
        map.current.setFilter("countries-highlighted1", options);
      }
    }
  }

  //END OF MAP FUNCTIONALITY IMPLEMENTATION

  // BEGINING OF SIDE MENU IMPLEMENTATION

  useEffect(() => {
    
    $("#mission").on("scroll", function () {
      // This function is not being called when scrolling in the mission
      var link = $(".landing-navbar a.dot");
      var top = $(window).scrollTop();

      $(".sec").each(function () {
        var id = $(this).attr("id");
        var height = $(this).height();
        var offset = $(this).offset().top - 500;
        if (top >= offset && top < offset + height) {
          link.removeClass("active");
          $(".landing-navbar")
            .find('[data-scroll="' + id + '"]')
            .addClass("active");
        }
      });

      for (const chapterName in chapters) {
        //console.log("Mission scroll => chapter: " + chapterName);
        if (isElementOnScreen(chapterName)) {
          //console.log("j", chapterName);
          setActiveChapterName(chapterName);
          break;
        }
      }
    });
  }, []);

  // useEffect(() => {
  //   console.log("I'm called");
  //   window.onscroll = () => {
  //     for (const chapterName in chapters) {
  //         if (isElementOnScreen(chapterName)) {
  //             setActiveChapter(chapterName);
  //             break;
  //         }
  //     }
  //   };
  // });

  //

  // useEffect(() => {

  //   window.onscroll = () => {
  //     for (const chapterName in chapters) {
  //         if (isElementOnScreen(chapterName)) {
  //             setActiveChapter(chapterName);
  //             break;
  //         }
  //     }
  // };
  // }, []);

  const onStepEnter = ({ data }) => {
    //console.log("onStepEnter: " + data);
    //console.log("Active chapter..." + activeChapterName);

    if (data !== activeChapterName) {
      //console.log("Changing active dot");
      document.getElementById(data + "-dot").classList.add("active");
      document
        .getElementById(activeChapterName + "-dot")
        .classList.remove("active");
      setActiveChapterName(data);
    }

    if (mapLayersLoaded) {
      //console.log("Map is loaded");
      if (data !== steps[currentStep]) {
        clearMapCountryColours();
        setCurrentStep(steps.indexOf(data));
        flyToLocation(data);
        changeActiveCountryColour(data);
      }
    }
  };

  return (
    <div>
      <LandingNav onClick={handleClick} />
      <div className="land-flexbox-container">
        <div className="container-fluid land-flexbox-item land-flexbox-item-1">
          <div ref={mapContainer} className="land-map"></div>
        </div>

        <div
          className="land-flexbox-item land-flexbox-item-2 features"
          id="mission"
          // style={{ width: '35%', backgroundColor: 'white' }}
        >
          <nav className="landing-navbar">
            <ul>
              <li>
                <a
                  id="unef1-dot"
                  href="#unef1"
                  className="dot active"
                  data-scroll="unef1"
                >
                  <span>{t("sideMenu.one")}</span>
                </a>
              </li>

              <li>
                <a
                  id="onuc-dot"
                  href="#onuc"
                  className="dot"
                  data-scroll="onuc"
                >
                  <span>{t("sideMenu.two")}</span>
                </a>
              </li>

              <li>
                <a
                  id="unprofor-dot"
                  href="#unprofor"
                  className="dot"
                  data-scroll="unprofor"
                >
                  <span>{t("sideMenu.three")}</span>
                </a>
              </li>

              <li>
                <a
                  id="defense-of-canada-dot"
                  href="#defense-of-canada"
                  className="dot"
                  data-scroll="defense-of-canada"
                >
                  <span>{t("sideMenu.four")}</span>
                </a>
              </li>

              <li>
                <a
                  id="unficyp-dot"
                  href="#unficyp"
                  className="dot"
                  data-scroll="unficyp"
                >
                  <span>{t("sideMenu.five")}</span>
                </a>
              </li>
              <li>
                <a
                  id="vietnam-dot"
                  href="#vietnam"
                  className="dot"
                  data-scroll="vietnam"
                >
                  <span>{t("sideMenu.six")}</span>
                </a>
              </li>
              <li>
                <a
                  id="defense-of-germany-dot"
                  href="#defense-of-germany"
                  className="dot"
                  data-scroll="defense-of-germany"
                >
                  <span>{t("sideMenu.seven")}</span>
                </a>
              </li>
              <li>
                <a
                  id="afghanistan-dot"
                  href="#afghanistan"
                  className="dot"
                  data-scroll="afghanistan"
                >
                  <span>{t("sideMenu.eight")}</span>
                </a>
              </li>
            </ul>
          </nav>

          <div className="sel-mission-mobile-container">
            <p className="sel-mission-mobile">{t("missionPageTitle")}</p>

            <p className="sel-mission-mobile-arrow">↓</p>
          </div>
          <div className="sel-mission-container">
            <h2 className="sel-mission">{t("missionPageTitle")}</h2>
          </div>
          <div className="missions-section">
            <Scrollama
              onStepEnter={onStepEnter}
              //onStepExit={onStepExit}
              progress
              //onStepProgress={onStepProgress}
              offset="250px"
              debug={false}
            >
              <Step data="unef1" key="unef1">
                <section id="unef1" className="active sec-f">
                  <div className="land-card js-navDots">
                    <h1 className="land-texthead">
                      {" "}
                      {t("missionOne.titleOne")}{" "}
                    </h1>
                    <h1 className="land-texthead">
                      {" "}
                      {t("missionOne.titleTwo")}{" "}
                    </h1>
                    <div className="h-image-container">
                      <img
                        className="land-card-image"
                        src="/assets/1/image/image/unef_ferret.jpg"
                      />
                      <div className="hover-text">
                        <p className="title-img-text">
                          {" "}
                          {t("missionOne.image.title")}{" "}
                        </p>
                        <p className="img-text">
                          {" "}
                          {t("missionOne.image.description")}{" "}
                        </p>
                        <p className="img-source">
                          {" "}
                          {t("missionOne.image.source")}{" "}
                        </p>
                      </div>
                    </div>
                    <p className="land-text">{t("missionOne.description")}</p>
                    <Link
                      to="/mission-control"
                      state={{ mission_id: 1, language: language }}
                    >
                      <button className="mission-btn">
                        {t("missionOne.buttonTextOne")}
                      </button>
                    </Link>
                    <Link
                      to="/timeline"
                      state={{ mission_id: 1, language: language }}
                    >
                      <button className="activity-btn">
                        {t("missionOne.buttonTextTwo")}
                      </button>
                    </Link>
                  </div>
                </section>
              </Step>
              <Step data="onuc" key="onuc">
                <section id="onuc" className="sec">
                  <div className="land-card js-navDots">
                    <h1 className="land-texthead">
                      {" "}
                      {t("missionTwo.titleOne")}{" "}
                    </h1>
                    <p className="land-text">{t("missionTwo.description")}</p>

                    <div className="alert-mission">
                      <h2 className="coming-soon">
                        {" "}
                        {t("missionTwo.comingSoonTitle")}
                      </h2>
                      <p className="alert-text-soon">
                        {t("missionTwo.comingSoonDescription")}{" "}
                      </p>
                    </div>
                    {/* <Link to="/mission-control">
                      <button className='mission-btn'> View Mission
                      </button>
                      </Link>
                      <Link to="/student-teacher-activities">
                      <button className='activity-btn'> View Activities
                      </button>
                      </Link> */}
                  </div>
                </section>
              </Step>
              <Step data="unprofor" key="unprofor">
                <section id="unprofor" className="sec">
                  <div className="land-card js-navDots">
                    <h1 className="land-texthead">
                      {" "}
                      {t("missionThree.titleOne")}
                    </h1>
                    <p className="land-text">{t("missionThree.description")}</p>
                    <Link
                      to="/mission-control"
                      state={{ mission_id: 8, language: language }}
                    >
                      <button className="mission-btn">
                        {" "}
                        {t("missionThree.buttonTextOne")}
                      </button>
                    </Link>
                    <Link
                      to="/timeline"
                      state={{ mission_id: 8, language: language }}
                    >
                      <button className="activity-btn">
                        {" "}
                        {t("missionThree.buttonTextTwo")}
                      </button>
                    </Link>
                  </div>
                </section>
              </Step>
              <Step data="defense-of-canada" key="defense-of-canada">
                <section id="defense-of-canada" className="sec">
                  <div className="land-card js-navDots">
                    <h1 className="land-texthead">
                      {" "}
                      {t("missionFour.titleOne")}{" "}
                    </h1>
                    <p className="land-text">{t("missionFour.description")}</p>

                    <div className="alert-mission">
                      <h2 className="coming-soon">
                        {" "}
                        {t("missionFour.comingSoonTitle")}{" "}
                      </h2>
                      <p className="alert-text-soon">
                        {" "}
                        {t("missionFour.comingSoonDescription")}
                      </p>
                    </div>
                    {/* <Link to="/mission-control">
                      <button className='mission-btn'> View Mission
                      </button>
                      </Link>
                      <Link to="/student-teacher-activities">
                      <button className='activity-btn'> View Activities
                      </button>
                      </Link> */}
                  </div>
                </section>
              </Step>
              <Step data="unficyp" key="unficyp">
                <section id="unficyp" className="sec">
                  <div className="land-card js-navDots">
                    <h1 className="land-texthead">
                      {" "}
                      {t("missionFive.titleOne")}
                    </h1>
                    <p className="land-text">{t("missionFive.description")}</p>

                    <div className="alert-mission">
                      <h2 className="coming-soon">
                        {" "}
                        {t("missionFive.comingSoonTitle")}{" "}
                      </h2>
                      <p className="alert-text-soon">
                        {" "}
                        {t("missionFive.comingSoonDescription")}{" "}
                      </p>
                    </div>
                    {/* <Link to="/mission-control">
                      <button className='mission-btn'> View Mission
                      </button>
                      </Link>
                      <Link to="/student-teacher-activities">
                      <button className='activity-btn'> View Activities
                      </button>
                      </Link> */}
                  </div>
                </section>
              </Step>
              <Step data="vietnam" key="vietnam">
                <section id="vietnam" className="sec">
                  <div className="land-card js-navDots">
                    <h1 className="land-texthead">
                      {" "}
                      {t("missionSix.titleOne")}{" "}
                    </h1>
                    <p className="land-text">{t("missionSix.description")}</p>

                    <div className="alert-mission">
                      <h2 className="coming-soon"> {t('missionSix.comingSoonTitle')}</h2>
                      <p className="alert-text-soon">

                      {t('missionSix.comingSoonDescription')}
                        
                      </p>
                    </div>
                    {/* <Link to="/mission-control">
                      <button className='mission-btn'> View Mission
                      </button>
                      </Link>
                      <Link to="/student-teacher-activities">
                      <button className='activity-btn'> View Activities
                      </button>
                      </Link> */}
                  </div>
                </section>
              </Step>
              <Step data="defense-of-germany" key="defense-of-germany">
                <section id="defense-of-germany" className="sec">
                  <div className="land-card js-navDots">
                    <h1 className="land-texthead"> {t('missionSeven.titleOne')}</h1>
                    <p className="land-text">
                    {t('missionSeven.description')}
                  
                    </p>

                    <div className="alert-mission">
                      <h2 className="coming-soon">{t('missionSeven.comingSoonTitle')}</h2>
                      <p className="alert-text-soon">
                      {t('missionSeven.comingSoonDescription')}
                      </p>
                    </div>
                    {/* <Link to="/mission-control">
                      <button className='mission-btn'> View Mission
                      </button>
                      </Link>
                      <Link to="/student-teacher-activities">
                      <button className='activity-btn'> View Activities
                      </button>
                      </Link> */}
                  </div>
                </section>
              </Step>
              <Step data="afghanistan" key="afghanistan">
                <section id="afghanistan" className="sec">
                  <div className="land-card js-navDots">
                    <h1 className="land-texthead"> {t('missionEight.titleOne')}</h1>
                    <h1 className="land-texthead">

                    {t('missionEight.titleTwo')}
                      
                    </h1>
                    <div className="h-image-container">
                      <img
                        className="land-card-image"
                        src="/assets/7/image/image/afghanistan_title.jpg"
                      />
                      <div className="hover-text">
                        <p className="title-img-text">

                        {t('missionEight.image.title')}
                          
                        </p>
                        <p className="img-text">

                        {t('missionEight.image.description')}
                        
                        </p>
                        <p className="img-source">

                        {t('missionEight.image.source')}
                          
                          
                        </p>
                      </div>
                    </div>
                    <p className="land-text">

                    {t('missionEight.description')}
                
                
                    </p>

                    {/* <div className='alert-mission'>
                    <h2 className='coming-soon'>COMING SOON</h2>
                    <p className='alert-text-soon'> The "Afghanistan" mission is currently a work in progress </p>
                    </div> */}
                    <Link
                      to="/mission-control"
                      state={{ mission_id: 7, language: language }}
                    >
                      <button className="mission-btn">  {t('missionEight.buttonTextOne')}</button>
                    </Link>
                    <Link
                      to="/timeline"
                      state={{ mission_id: 7, language: language }}
                    >
                      <button className="activity-btn"> {t('missionEight.buttonTextTwo')}</button>
                    </Link>
                    {/* <Link to="/mission-control">
                      <button className='mission-btn'> View Mission
                      </button>
                      </Link>
                      <Link to="/student-teacher-activities">
                      <button className='activity-btn'> View Activities
                      </button>
                      </Link> */}
                  </div>
                  {/* <small id="citation">
                        Adapted from
                        <a href="http://www.gutenberg.org/files/2346/2346-h/2346-h.htm">Project Gutenberg</a>
                    </small> */}
                </section>
              </Step>
            </Scrollama>
          </div>
        </div>
      </div>
    </div>
  );
}

// function setActiveChapter(chapterName) {
//   console.log("setActive Chapter" + chapterName + " - " + activeChapterName);
//   if (chapterName === activeChapterName) return;

//  // map.flyTo(chapters[chapterName]);
//   console.log("flied to",chapters[chapterName]);
//   document.getElementById(chapterName).classList.add('active');
//   document.getElementById(activeChapterName).classList.remove('active');

//   activeChapterName = chapterName;
// }

function isElementOnScreen(id) {
  //console.log("isElementOnScreen ");
  const element = document.getElementById(id);
  const bounds = element.getBoundingClientRect();
  return bounds.top < window.innerHeight && bounds.bottom > 0;
}
export default LandingPage;
