import {useRef, useEffect, useState} from 'react'
import '../Navigation/Sidebar.css'
import "./OneDayOption.css"
import TopNav from '../Navigation/TopNav';
import { slide as Menu } from 'react-burger-menu'
import { Link, useLocation } from 'react-router-dom';
import IntroductionVideo from './IntroductionVideo3';
import MissionControlActivity from './MissionControlActivity';
import TimelineActivity from './TimelineActivity3'
import CanadasRole from './CanadasRole'
import InquiryQuestion from './InquiryQuestion'
import VeteranTestimonials from './VeteranTestimonials'
import InterrogatingPhoto from './InterrogatingPhoto'
import Alliances from './Alliances'
import { useTranslation } from 'react-i18next';



function ThreeDayOption() {

     // Use the studentActivities namespace
    const {t,i18n} = useTranslation('studentActivities')
    
    // These variables are for the props to be passed between pages
    const location = useLocation();
    const { state } = location;
    const [missionID, setMissionID] = useState(state.mission_id);
    const [language, setLanguage] = useState(i18n.language);
    function handleClick(newLang) {
    
    }

    const introvideo = useRef(null);
    const missioncontrol = useRef(null);
    const canadasrole = useRef(null);
    const inquiry = useRef(null);
    const timeline = useRef(null);
    const veterantest = useRef(null);
	const photoactivity = useRef(null);
    const alliances = useRef(null);
    

    useEffect(()=>{
        setMissionID(state.mission_id);
        setLanguage(state.language);
        console.log("Three Day Option - Mission ID: " + missionID + " - Language: " + language);
       
    }, [])

    const scrollToSection = (elementRef) => {
       
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth'
            
        });
        

    };
   
    
        return(
            <div>
                <TopNav onClick={handleClick}/>
        <div className=''>
     <div className=''>

        <Menu>
                 <nav className='stop-nav-items'> 
             
     <Link to="/" style={{ textDecoration: 'none' }}>
                           
						   <i class=" fa-solid fa-house"></i> 
				 
								 
							 </Link>
             <div>
            <p className="comp-name"> {t('threeDay.title')}</p>
            <hr className='hrline' />
            </div>
             
             
            <ul>
                <li onClick = {() => scrollToSection(introvideo) }
                    className="cr-side-link"> 
                    <i class="fa-solid fa-gear"></i> 
                      {t('introductionVideo.one.title')}
                 </li>
                 <li onClick = {() => scrollToSection(missioncontrol) }
                    className="cr-side-link"> 
                    <i class="fa-solid fa-gear"></i> 
                  {t('missionControl.title')}
                 </li>
                 <li onClick = {() => scrollToSection(canadasrole) }
                    className="cr-side-link"> 
                    <i class="fa-solid fa-gear"></i> 
                      {t('canadasRole.title')}
                 </li>
                <li  onClick = {() => scrollToSection(inquiry)} 
                     className="cr-side-link"> 
                     <i class="fa-solid fa-gear"></i> 
                    {t('inquiryQuestion.title')}
                </li>
                <li  onClick = {() => scrollToSection(timeline)}
                     className="cr-side-link">
                         <i class="fa-solid fa-gear"></i> 
                     {t('timeline.one.title')}
                </li>
                <li  onClick = {() => scrollToSection(veterantest)}
                         className="cr-side-link"> 
                        <i class="fa-solid fa-gear"></i> 
                     {t('veteranTestimonials.title')}
                  </li>
				  <li  onClick = {() => scrollToSection(photoactivity)}
                         className="cr-side-link"> 
                        <i class="fa-solid fa-gear"></i> 
                      {t('interrogatingPhoto.title')}
                  </li>
                  <li  onClick = {() => scrollToSection(alliances)}
                         className="cr-side-link"> 
                        <i class="fa-solid fa-gear"></i> 
                    {t('alliances.title')}
                  </li>
                  
            </ul>

             
             
                
            
             
            </nav>

            </Menu>

                </div>

            <div className='activity-back'>

           
            <div className='option-summary'>
            <h2 className='sum-para-head'>   {t('threeDay.title')}</h2>
            <p className='sum-para'> {t('threeDay.subTitle')} </p>
            
            <div className='rec-card'>

            <p>

            {t('threeDay.description')}
            
            </p>
            </div>
            
            </div>    
            
                <div ref ={introvideo}>
                
                <IntroductionVideo />		
             
            </div>
        
            <div ref ={missioncontrol}>
                
                <MissionControlActivity />		
             
            </div>

            <div ref = {timeline}>
            
                <TimelineActivity />
			
            </div>



            <div ref ={canadasrole}>
                
            <CanadasRole />	
			
            </div>

           

            <div ref = {inquiry}>
            

            <InquiryQuestion />
			
			
            </div>

           


            <div ref ={veterantest}>
            
            <VeteranTestimonials />
			
            </div>



			<div ref ={photoactivity}>
            

            <InterrogatingPhoto />
			
            </div>

            

			<div ref ={alliances}>
            
			
            <Alliances />
			
            </div>
    </div>
    </div>	
    </div>
            
        )
    }



export default ThreeDayOption
