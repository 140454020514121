import { useState } from 'react';
import React from 'react';
import { useCollapse } from 'react-collapsed';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function IntroductionVideo3({ title, children }) {



    const {t,i18n} = useTranslation("studentActivities")
    
         const textFormat = (text) =>
            text.split('\n').map((line, index) => (
        
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ));


  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    // These variables are for the props to be passed between pages
    const location = useLocation();
    console.log ("Intro video activity3 useLocation: " + JSON.stringify(location));
    const { state } = location;
    const [missionID, setMissionID] = useState(state.mission_id);
    const [language, setLanguage] = useState(i18n.language);

  return (
      <div>
        <div className='activity-sec'>
                <h2  className='activity-title'>
                  {t("introductionVideo.three.title")}
                </h2>
                  
                  <div className='activity-content'>
                  
                     <p className='mission-text'> {t("introductionVideo.three.subTitle")}  </p>
                      
  
                      <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("introductionVideo.three.collapse") : t("introductionVideo.three.expand") }</button>
                      <h2>{title}</h2>
                
  
                      <div {...getCollapseProps()}>{isExpanded && children}
                    
     
                          {/* <h3 className='outline-head'>Mission</h3>
                          <p className='outline-text'> Watch a video that outlines the conflict and the events that led 
                          to the need for international involvement.   </p> */}
                          
  
                          <h3 className='outline-head'> {t("introductionVideo.three.objective.title")}</h3>
  
                          <p className='outline-text'>  {textFormat(t('introductionVideo.three.objective.description'))} 
                              <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>
                              
                               {t("introductionVideo.three.objective.link")}
                              
                               </a>  {textFormat(t("introductionVideo.three.objective.descriptionContinued"))}
                               
                               </p>
  
  
                          <h3 className='outline-head'>  {t("introductionVideo.three.actionSteps.title")}</h3>
                          <p className='outline-text'> {t('introductionVideo.three.actionSteps.description')} 
                              <Link to="/mission-control" state={{mission_id:missionID, language:language}}> {t('introductionVideo.three.actionSteps.link')} </Link>
                              {textFormat(t("introductionVideo.three.actionSteps.descriptionContinued"))}</p>
  
                          <h3 className='outline-head'> {t("introductionVideo.three.questions.title")}</h3>
                          <p className='outline-text'> 
  
                          {textFormat(t('introductionVideo.three.questions.description'))} 
                          </p>
  
  
                          <h3 className='outline-head'> {t("introductionVideo.three.debrief.title")}</h3>
                          <p className='outline-text'> 
                          {textFormat(t("introductionVideo.three.debrief.description"))}
                             
                              <ul>
                                  <li><Link to="/mission-control" state={{mission_id:missionID, language:language}}>  {t("introductionVideo.three.debrief.link1")}</Link></li>
                                  <li> <Link to="/timeline" state={{mission_id:missionID, language:language, timeline_filter:1}}>   {t("introductionVideo.three.debrief.link2")}</Link></li>
                              </ul>
                              
                          </p>
                     </div>
                  </div>
            </div>
        
        
      </div>
  );
}

export default IntroductionVideo3;