import React, { useState } from "react";
import "./LandingNav.css";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { slide as Menu } from "react-burger-menu";
import { useTranslation } from "react-i18next";



//function LandingNav() {
function LandingNav( { onClick } ) {

  const {t, i18n} = useTranslation("common")

  // These variables are for the props to be passed between pages
  const location = useLocation();
  const { state } = location;
  //console.log("LandingNav state:  " + JSON.stringify(state) + " i18next language: " + i18n.language);
  const [language, setLanguage] = useState(i18n.language); //useState(state.language);

  

  // Function to change between languages (English and French)
  const toggleLanguage = () => {
    const currentLang = i18n.language; // Get the current language
    const newLang = currentLang === 'en' ? 'fr' : 'en'; // Toggle between 'en' and 'fr'
    i18n.changeLanguage(newLang); // Change to the new language
    localStorage.setItem('language', newLang); // Save selected language

    // console.log("Landing Nav new language: " + newLang);
    onClick(newLang)

  };

  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);

  const toggleResources = () => {
    setIsResourcesOpen(!isResourcesOpen);
    setIsAboutOpen(false);
  };

  const toggleAbout = () => {
    setIsAboutOpen(!isAboutOpen);
    setIsResourcesOpen(false);
  };



  return (
    <div>
    
  <Menu>
          <nav className='stop-nav-items '>

		  <NavLink to="/" style={{ textDecoration: 'none' }}>
                           
						   <i class=" fa-solid fa-house"></i> 
				 
								 
							 </NavLink>

               
            <div>
              <p className='comp-name'>  {t('navigation.title')}</p>
              <hr className='hrline' />
              
            </div>
            
            <ul style= {{ listStyleType: 'none' }}>
          
			<NavLink className='link-line' to="/" >
              <li
                className='land-side-link menu-item'
              >
                <i class='fa-solid fa-gear'></i>  {t('navigation.home')}
              </li>
			  </NavLink>
			  <NavLink className='link-line' to="/missions" >
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i>   {t('navigation.missions')}
              </li>
			  </NavLink>
			  <NavLink className='link-line' to="/about-us" >
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i>  {t('navigation.about')}
              </li>
			  </NavLink>
        <NavLink className='link-line' to="/team" >
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i>   {t('navigation.ourTeam')}
              </li>
			  </NavLink>
        <NavLink className='link-line' to="/teacher-resources" >
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i>   {t('navigation.teacherResources')}
              </li>
			  </NavLink>
        <NavLink className='link-line' to="/veteran-interviews" >
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i>  {t('navigation.veteranInterviews')}
              </li>
			  </NavLink>
        <NavLink className='link-line' to="/contact" >
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i>   {t('navigation.contact')}
              </li>
			  </NavLink>
        <NavLink className='link-line' to="" onClick={toggleLanguage} >
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i>   {t('navigation.language')}
              </li>
			  </NavLink>
            </ul>
          </nav>
    </Menu>
    <nav className="main-container">
          <div className="land-logo-section">
        <img loading="lazy" src="/gregg_logo.png" className="logo" />
       
        <NavLink to='/' style={{ textDecoration: 'none' }}>
        <div className="title">

        {t('navigation.title')}

          </div>
            </NavLink>
      </div>

     
     <div
       className="navigation-menu"
    >

      <ul className="menu">

      <li className="land-li "><NavLink to="/" className="land-nav-links" activeClassName="active" >
      
      {t('navigation.home')}
      
      </NavLink></li>
        <li className="land-li"><NavLink to="/missions" className="land-nav-links" activeClassName="active" >
        
        {t('navigation.missions')}
        
        </NavLink></li>

        <li className="land-li" onClick={toggleAbout}>
        <span className="land-nav-links">

          {t('navigation.about')} ▼

          </span>


        {isAboutOpen && (
            <ul className="resources-dropdown">
              <NavLink to="/about-us" className="dropdown-item" activeClassName="active" >
                <li className="dropdown-item-li">

                 {t('navigation.aboutProject')}

                </li>
              </NavLink>

              <NavLink to="/team" className="dropdown-item" activeClassName="active" >
                <li className="dropdown-item-li">

                  {t('navigation.ourTeam')}

                </li>
              </NavLink>
          
            </ul>
          )}
          
          
          
          </li>



        <li className="land-li" onClick={toggleResources}>
        <span className="land-nav-links">
          
        {t('navigation.resources')}▼
          
          </span>


        {isResourcesOpen && (
            <ul className="resources-dropdown">
              <NavLink to="/teacher-resources" className="dropdown-item" activeClassName="active" >
                <li className="dropdown-item-li">

                {t('navigation.teacherResources')}

                </li>
              </NavLink>

              <NavLink to="/veteran-interviews" className="dropdown-item" activeClassName="active" >
                <li className="dropdown-item-li">

                 {t('navigation.veteranInterviews')}

                </li>
              </NavLink>
          
            </ul>
          )}
          
          
          
          </li>
       
       
       
        <li className="land-li"><NavLink to="/contact" className="land-nav-links" activeClassName="active" >
        
        {t('navigation.contact')}
        
        </NavLink></li>


        <li className="land-li"><NavLink to="" className="lang-sel-btn" onClick={toggleLanguage} >
        
         
        <img className="lang-logo" src="/lang-logo.png"/>
         
        
        {t('navigation.language')}
        
        
        </NavLink></li>
       
        
         
      

      </ul>

      </div>
    </nav>
    </div>
  );
}

export default LandingNav;