import React from 'react';
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';

function MajorPlayers({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();


     const {t} = useTranslation("studentActivities")
    
    
       
                          const textFormat = (text) =>
                             text.split('\n').map((line, index) => (
                         
                               <React.Fragment key={index}>
                                 {line}
                                 <br />
                               </React.Fragment>
                             ));

  return (
    
    <div>
    <div className='activity-sec'>
      <h2  className='activity-title'>
          {t("majorPlayers.title")}
      </h2>


              <div className='activity-content'>

              <p className='mission-text'> {t("majorPlayers.subTitle")} </p>
              

            
              <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("majorPlayers.collapse") : t("majorPlayers.expand")}</button>
          <h2>{title}</h2>

          <div {...getCollapseProps()}>{isExpanded && children}
            
              
             
          <h3 className='outline-head'> {t("majorPlayers.objective.title")}</h3>
                <p className='outline-text'>  {t("majorPlayers.objective.description")} </p>



                <h3 className='outline-head'> {t("majorPlayers.actionSteps.title")}</h3>
                <p className='outline-text'>  {textFormat(t("majorPlayers.actionSteps.description"))} </p>

             
             
                <h3 className='outline-head'> {t("majorPlayers.questions.title")}</h3>
                <p className='outline-text'>  {textFormat(t("majorPlayers.questions.description"))} </p>

            


                <h3 className='outline-head'> {t("majorPlayers.debrief.title")}</h3>
                <p className='outline-text'>  {textFormat(t("majorPlayers.debrief.description"))} </p>

              </div>
              </div>
    </div>
  </div>


  );
}

export default MajorPlayers;