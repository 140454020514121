import { useState } from 'react';
import React from 'react';
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';

function InquiryQuestion({ title, children }) {


  const {t} = useTranslation("studentActivities")
          
               const textFormat = (text) =>
                  text.split('\n').map((line, index) => (
              
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ));

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div>

      <div className='activity-sec'>
				<h2  className='activity-title'>
                  {t("inquiryQuestion.title")}
				</h2>
        <div className='activity-content'>

          <p className='mission-text'>{t("inquiryQuestion.subTitle")} </p>


          <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("inquiryQuestion.collapse") : t("inquiryQuestion.expand")}</button>
          <h2>{title}</h2>

          <div {...getCollapseProps()}>{isExpanded && children}
            



          <h3 className='outline-head'> {t("inquiryQuestion.objective.title")}</h3>
                <p className='outline-text'> {textFormat(t("inquiryQuestion.objective.descriptionOne"))} 
                {t("inquiryQuestion.objective.descriptionTwo")} 

                    <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>  {t("inquiryQuestion.objective.link")}</a>  
                    {textFormat(t("inquiryQuestion.objective.descriptionContinued"))}
                </p>


          <h3 className='outline-head'> {t("inquiryQuestion.actionSteps.title")}</h3>
                          <p className='outline-text'> 
                          {t("inquiryQuestion.actionSteps.description")}
                          
                          </p>


                          <h3 className='outline-head'> {t("inquiryQuestion.questions.title")}</h3>
                <p className='outline-text'> 
                {textFormat(t('inquiryQuestion.questions.description'))} 

                </p>

                <h3 className='outline-head'>  {t("inquiryQuestion.debrief.title")}</h3>
                <p className='outline-text'>  {textFormat(t("inquiryQuestion.debrief.description"))}
                                  </p>


      </div>
</div>
			</div>
    </div>

    
  );
}

export default InquiryQuestion;