import { useState } from 'react';
import React from 'react';
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

function IntroductionVideo1({ title, children }) {

    const {t,i18n} = useTranslation("studentActivities")

     const textFormat = (text) =>
        text.split('\n').map((line, index) => (
    
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  // These variables are for the props to be passed between pages
  const location = useLocation();
  console.log ("Intro video activity1 useLocation: " + JSON.stringify(location));
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(i18n.language);

  return (
    <div>
      <div className='activity-sec'>
				<h2  className='activity-title'>
                {t("introductionVideo.one.title")}
				</h2>
                
                <div className='activity-content'>
                
                   <p className='mission-text'> {t("introductionVideo.one.subTitle")}  </p>
                    

                    <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("introductionVideo.one.collapse") : t("introductionVideo.one.expand") }</button>
                    <h2>{title}</h2>
              

                    <div {...getCollapseProps()}>{isExpanded && children}
                  
   
                        {/* <h3 className='outline-head'>Mission</h3>
                        <p className='outline-text'> Watch a video that outlines the conflict and the events that led 
                        to the need for international involvement.   </p> */}
                        

                        <h3 className='outline-head'> {t("introductionVideo.one.objective.title")}</h3>

                        <p className='outline-text'>  {textFormat(t('introductionVideo.one.objective.description'))} 
                            <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>
                            
                             {t("introductionVideo.one.objective.link")}
                            
                             </a>  {textFormat(t("introductionVideo.one.objective.descriptionContinued"))}
                             
                             </p>


                        <h3 className='outline-head'>  {t("introductionVideo.one.actionSteps.title")}</h3>
                        <p className='outline-text'> {t('introductionVideo.one.actionSteps.description')} 
                            <Link to="/mission-control" state={{mission_id:missionID, language:language}}> {t('introductionVideo.one.actionSteps.link')} </Link>
                            {textFormat(t("introductionVideo.one.actionSteps.descriptionContinued"))}</p>

                        <h3 className='outline-head'> {t("introductionVideo.one.questions.title")}</h3>
                        <p className='outline-text'> 

                        {textFormat(t('introductionVideo.one.questions.description'))} 
                        </p>


                        <h3 className='outline-head'> {t("introductionVideo.one.debrief.title")}</h3>
                        <p className='outline-text'> 
                        {textFormat(t("introductionVideo.one.debrief.description"))}
                           
                            <ul>
                                <li><Link to="/mission-control" state={{mission_id:missionID, language:language}}>  {t("introductionVideo.one.debrief.link1")}</Link></li>
                                <li> <Link to="/timeline" state={{mission_id:missionID, language:language, timeline_filter:1}}>   {t("introductionVideo.one.debrief.link2")}</Link></li>
                            </ul>
                            
                        </p>
                   </div>
                </div>
			</div>
      
      
    </div>
  );
}

export default IntroductionVideo1;