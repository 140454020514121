import React from 'react';
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';

function VeteranTestimonials({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    const {t} = useTranslation("studentActivities")

  return (


   
    <div>

<div>

<div className='activity-sec'>
  <h2  className='activity-title'>
            {t("veteranTestimonials.title")}
  </h2>
  <div className='activity-content'>

    <p className='mission-text'>{t("veteranTestimonials.subTitle")} </p>


    <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("veteranTestimonials.collapse") : t("veteranTestimonials.expand")}</button>
    <h2>{title}</h2>

    <div {...getCollapseProps()}>{isExpanded && children}
      



    <h3 className='outline-head'> {t("veteranTestimonials.objective.title")}</h3>
          <p className='outline-text'> {t("veteranTestimonials.objective.description")} 
         
          </p>


    <h3 className='outline-head'> {t("veteranTestimonials.actionSteps.title")}</h3>
                    <p className='outline-text'> 
                    {t("veteranTestimonials.actionSteps.description")}
                    
                    </p>


                    <h3 className='outline-head'> {t("veteranTestimonials.questions.title")}</h3>
          <p className='outline-text'> 
          {t('veteranTestimonials.questions.description')} 

          </p>

          <h3 className='outline-head'>  {t("veteranTestimonials.debrief.title")}</h3>
          <p className='outline-text'>  {t("veteranTestimonials.debrief.description")}
                            </p>


</div>
</div>
</div>
</div>

  </div>

  );
}

export default VeteranTestimonials;