import React from 'react';
import './VeteranInterviews.css';
import './TeacherResources.css';
import LandingNav from '../Navigation/LandingNav';
import Footer from '../Navigation/Footer';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { Document, Page } from 'react-pdf';
    
function VeteranInterviews(){


  // Use the veteranInterviews namespace
  const {t,i18n} = useTranslation('veteranInterviews')

  function handleClick(newLang) {
    
  }
  

  // Access the interview data directly from the translation file
  const interviewData = t('interviews', { returnObjects: true });






/*
    const [interviewData,setData] = useState([]);

   const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };

    const getData=()=>{
      fetch('../assets/PK75/PK75.json'
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      )
      .then(function(response){
        //console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        //console.log(myJson);
        setData(myJson)

      });
    }

    useEffect(()=>{
      getData()
    },[])
    */

    
    
    return(
        <div>
            <LandingNav onClick={handleClick} />
            {/* <LandingNav onClick={handleClick} state={{language: language}} /> */}

            {/* Veteran Interviews Header Section */}
            <div className='veteran-header'>
                    <img
                    loading="lazy"
                    src='/veteran-header-image.jpg'
                    className='teacher-header-image'
                    />
            <div className='teacher-header-content'>

            <h2 className='teacher-header-heading'> 
               {t('header')}
            </h2>

            <p className='teacher-header-text'> {t('description')}</p>

            </div>

            </div>




            {/* Veteran Interviews List Section */}

            <div className='veteran-list-container'>

                <div className='veteran-list-header'>
                    <h2 className='veteran-list-title'> {t('title')}</h2>
                    <p className='veteran-list-subtitle'> {t('subtitle')}</p>
                </div>




                        {/* <div className='veteran-list'>

                    { interviewData.map((interview_info) => {
                      return[
                        
                        <div className='veteran-card'>

                            <h2 className='vi-card-name'>{interview_info.Surname}, {interview_info.Given_Name}</h2>
                            <p className='vi-card-dash'> _ </p>  
                            <p className='vi-card-title'>{interview_info.Rank}</p>
                            <p className='vi-card-location'>Mission: {interview_info.Mission} ({interview_info.Year})</p>

                            <a className='veteran-card-button' href={"../assets/PK75/"+interview_info.First_PDF} target='_blank' rel="noreferrer">View Interview</a>
                            {/* <NavLink to={"#"}>
                              <button className="veteran-card-button"> 
                              <Link to={"../assets/PK75/"+interview_info.First_PDF} target="_blank" rel="noopener noreferrer" />
                                 <a href={"../assets/PK75/"+interview_info.First_PDF} target='_blank' rel="noreferrer">View Interview (Page 1)</a>
                              </button>
                            </NavLink>
                            <Document file={"../assets/PK75/"+interview_info.First_PDF} onLoadSuccess={onDocumentLoadSuccess} > 
                              <Page pageNumber={pageNumber} />
                            </Document> }
                        </div>
                      ];
                    })}


                    
                    

                </div>  */}

                    <div className="veteran-list">
                              {interviewData && interviewData.length > 0 ? (
                                interviewData.map((info, index) => (
                                  <div className="veteran-card" key={index}>
                                    <h2 className="vi-card-name">
                                      {info.Surname}, {info.Given_Name}
                                    </h2>
                                    <p className="vi-card-dash">_</p>
                                    <p className="vi-card-title">{info.Rank}</p>
                                    <p className="vi-card-location">
                                      {t('mission')} {info.Mission} ({info.Year})
                                    </p>
                                    <a
                                      className="veteran-card-button"
                                      href={`/assets/PK75/${info.First_PDF}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {t('view_interview')}
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p>{t('no_data')}</p> // Fallback message if no interview is available
                              )}
                            </div>

            </div>





            <Footer/>
        </div>
    )

}

export default VeteranInterviews;
  