import { useState } from 'react';
import React from 'react';
import { useCollapse } from 'react-collapsed';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function MissionControlActivity({ title, children }) {



    const {t,i18n} = useTranslation("studentActivities")
    
         const textFormat = (text) =>
            text.split('\n').map((line, index) => (
        
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ));


  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  // These variables are for the props to be passed between pages
  const location = useLocation();
  console.log ("Mission Control Activity useLocation: " + JSON.stringify(location));
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(i18n.language);

  console.log("MC Activity missionID: " + missionID);

  return (

    
    <div>
      <div className='activity-sec'>
				<h2  className='activity-title'>
               {t("missionControl.title")}
				</h2>


                <div className='activity-content'>

                <p className='mission-text'>  {t("missionControl.subTitle")}   </p>
                

                <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? t("missionControl.collapse") : t("missionControl.expand")}</button>
      <h2>{title}</h2>

      <div {...getCollapseProps()}>{isExpanded && children}
                   
                
               
      <h3 className='outline-head'>{t("missionControl.objective.title")}</h3>
                <p className='outline-text'>{textFormat(t("missionControl.objective.description"))} </p>
                <p className='outline-big6'>
                     <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>{t("missionControl.objective.link")}</a> {textFormat(t("missionControl.objective.descriptionContinued"))}  
                    
                </p>

                <h3 className='outline-head'>{t("missionControl.actionSteps.title")}</h3>
                <p className='outline-text'> {t("missionControl.actionSteps.description")} <Link to="/mission-control" state={{mission_id:missionID, language:language}}> {t("missionControl.actionSteps.link")}</Link>. 
                {textFormat(t("missionControl.actionSteps.descriptionContinued"))}
                    
                </p>

                <h3 className='outline-head'> {t("missionControl.questions.title")}</h3>
                <p className='outline-text'>  {textFormat(t("missionControl.questions.description"))}

                </p>


                <h3 className='outline-head'>{t("missionControl.debrief.title")}</h3>
                <p className='outline-text'> 
                {textFormat(t("missionControl.debrief.description"))}
                    <ul>
                        <li> <Link to="/mission-control" state={{mission_id:missionID, language:language}}> {t("missionControl.debrief.link1")}</Link></li>
                        <li> <Link to="/timeline" state={{mission_id:missionID, language:language, timeline_filter:1}}>  {t("missionControl.debrief.link2")}</Link></li>
                    </ul>
                </p>
                </div>
                </div>
			</div>
    </div>
  );
}

export default MissionControlActivity;