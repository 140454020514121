import React from "react";
import './Footer.css';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function Footer() {

  const {t, i18n} = useTranslation("common")

  return (
    <div>
         <footer className="footer-section">
      <header className="footer-header">
        <img
          loading="lazy"
         src="/gregg_logo.png"
         className="footer-header-img"
        />
        <div className="footer-header-description">
          
          {t('navigation.title')}
          
          </div>
      </header>
      <div className="footer-nav-container">
        <div className="footer-nav-items">
          <div className="footer-nav-item">
            <p className="footer-nav-title">

              {t('navigation.about')}

            </p>
          </div>
          <div className="footer-nav-item">
          <NavLink className="footer-nav-link" to={"/about-us"} >
          
            <p>

               {t('navigation.aboutProject')}

            </p>

            </NavLink>
          
          </div>
          <div className="footer-nav-item">
          <NavLink className="footer-nav-link" to={"/contact"} >

            <p>
              {t('navigation.contact')}
            </p>

            </NavLink>

          </div>

          <div className="footer-nav-item">
          <NavLink className="footer-nav-link" to={"/team"} >

            <p>

             {t('navigation.ourTeam')}

            </p>

            </NavLink>

          </div>
        </div>
        <div className="footer-nav-items">
        <div className="footer-nav-item">
            <p className="footer-nav-title">

               {t('navigation.resources')}

            </p>
          </div>
          <div className="footer-nav-item">
          <NavLink className="footer-nav-link" to={"/teacher-resources"} >
          
            <p>

               {t('navigation.teacherResources')}

            </p>

            </NavLink>
          
          </div>
          <div className="footer-nav-item">
          <NavLink className="footer-nav-link" to={"/veteran-interviews"} >

            <p>

             {t('navigation.veteranInterviews')}

            </p>

            </NavLink>

          </div>
        </div>
        <div className="footer-nav-items">
        <div className="footer-nav-item">
            <p className="footer-nav-title">

              {t('navigation.missions')}

            </p>
          </div>
          <div className="footer-nav-item">
          <NavLink className="footer-nav-link" to={"/missions"} >
          
          
            <p>

               {t('navigation.viewMissions')}

            </p>

            </NavLink>
          
          </div>
          <div className="footer-nav-item">
          <NavLink className="footer-nav-link" to={"/about-us"} >

            <p>
              FAQ
            </p>

            </NavLink>

          </div>
        </div>
      </div>
      

    </footer>

    <hr style={{width: '100%'}} />
<div className="copyright">


  <p className="copyright-text" >
     &copy; {new Date().getFullYear()}  {t('navigation.copyright')}
  </p>
  
   

</div>


</div>
 
  );
}

export default Footer;

