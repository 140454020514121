import React from "react";
import './Team.css';
import LandingNav from '../Navigation/LandingNav';
import Footer from '../Navigation/Footer';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
    
function Team(){

    const {t,i18n} = useTranslation("team")
    function handleClick(newLang) {
    
    }
    
    return(

        <div>

            <LandingNav onClick={handleClick} />
            {/* <LandingNav onClick={handleClick} state={{language: language}} /> */}

             {/* Team Section */}


             <div className='team-container'>

                <div className='team-heading'>

                    <h2 className='team-title'>  {t("title")} </h2>
                    <p className='team-subtitle'> {t("subTitle")}</p>

                </div>

                <div className='team-section'>




                <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Cindy Brown </h4>
                <p className='member-role'>  {t("memberOne.title")}  </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">
                    
                {t("memberOne.description")}
                </p>
            </div>





            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Lee A Windsor </h4>
                <p className='member-role'> {t("memberTwo.title")} </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">

                {t("memberTwo.description")}
                    
                    </p>
            </div>






            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Katelyn Stieva </h4>
                <p className='member-role'>
                    
                {t("memberThree.title")}

                </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">
                    
                {t("memberThree.description")}

                </p>
            </div>





            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Nancy Day </h4>
                <p className='member-role'>
                    
                {t("memberFour.title")}
                    
                     </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">
                    
                {t("memberFour.description")}

                </p>
            </div>





            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Blake Seward </h4>
                <p className='member-role'>
                    
                {t("memberFive.title")}

                     </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">
                    
                {t("memberFive.description")}

                </p>
            </div>






            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Mason Black </h4>
                <p className='member-role'>
                    
                {t("memberSix.title")}

                     </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">
                    
                {t("memberSix.description")}

                </p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Delaney Beck </h4>
                <p className='member-role'>
                    
                {t("memberSeven.title")}
                    
                     </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">
                    
                {t("memberSeven.description")}

                </p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Timothy Gerald </h4>
                <p className='member-role'>
                    
                {t("memberEight.title")}

                </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">
                    
                {t("memberEight.description")}

                </p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Darian Calhoun</h4>
                <p className='member-role'>
                    
                {t("memberNine.title")}

                     </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">
                    
                {t("memberNine.description")}

                </p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Taylor Lynn </h4>
                <p className='member-role'>
                    
                {t("memberTen.title")}

                </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">
                    
                {t("memberTen.description")}

                </p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Mackenzie Frances Smith </h4>
                <p className='member-role'>
                    
                {t("memberEleven.title")}

                     </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">
                    
                {t("memberEleven.description")}

                </p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Folajimi Oyemomi </h4>
                <p className='member-role'>
                    
                {t("memberTwelve.title")}
                    
                     </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">
                    

                {t("memberTwelve.description")}

                </p>
            </div>




                </div>




             </div>












            <Footer/>

        </div>


    )







}

export default Team;
  