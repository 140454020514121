import React from 'react'
import TopNav from '../Navigation/TopNav.js';
import {useRef, useEffect, useState} from 'react'
import '../Navigation/LandingSidebar.css'
import './StudentTeacherActivities.css'
import { NavLink, useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'
import { useTranslation } from 'react-i18next';



function StudentTeacherActivities()   {

	  // Use the studentActivities namespace
	  const {t,i18n} = useTranslation('studentActivities')
	  const { t: tCommon } = useTranslation("common"); // Access the "common" namespace
	
	// These variables are for the props to be passed between pages
    const location = useLocation();
    const { state } = location;
    const [missionID, setMissionID] = useState(state.mission_id);
    const [language, setLanguage] = useState(i18n.language);
	function handleClick(newLang) {
    
	}
	
	const photoActivity = useRef(null);
	
	useEffect(()=>{
        setMissionID(state.mission_id);
        setLanguage(state.language);
        console.log("Student Teacher Activities - Mission ID: " + missionID + " - Language: " + language);
       
    }, [])
		
		const scrollToSection = (elementRef) => {
		   
			window.scrollTo({
				top: elementRef.current.offsetTop,
				behavior: 'smooth'
				
			});
			
	
		};
			return(
				<div>
				<TopNav onClick={handleClick}/>
			<div className=''>
		 {/* <div className='flexbox-item flexbox-item-1'>
		 <nav className='stop-nav-items'> 
				 <div>
				<p className="comp-name">  ACTIVITIES</p>
				<hr className='hrline' />
				</div>
				 
				 
				 <ul>
				 <Link className='link-line' to="/oneday-option">
					<li 
						className="land-side-link"> 
						<i class="fa-solid fa-gear"></i> 
						One-Day Option
					 </li>
					 </Link>
					 <Link className='link-line' to="/threeday-option">
					 <li 
						className="land-side-link"> 
						<i class="fa-solid fa-gear"></i> 
						Three-Day Option
					 </li>
					 </Link>
					 <Link className='link-line' to="/fiveday-option">
					 <li 
						className="land-side-link"> 
						<i class="fa-solid fa-gear"></i> 
						Five-Day Option
					 </li>
					 </Link>
				</ul>
				 
				 
					
				
				 
				</nav>
					</div>
	 */}
	         <Menu>
          <nav className='stop-nav-items '>

		  <NavLink to="/" style={{ textDecoration: 'none' }} state={state}>
                           
						   <i class=" fa-solid fa-house"></i> 
				 
								 
							 </NavLink>
            <div>
              <p className='comp-name'>   {tCommon("navigation.activities")}</p>
              <hr className='hrline' />
            </div>
            <ul style= {{ listStyleType: 'none' }}>
			<NavLink className='link-line' to="/oneday-option" state={state}>
              <li
                className='land-side-link menu-item'
              >
                <i class='fa-solid fa-gear'></i>  {tCommon("navigation.oneDay")}
              </li>
			  </NavLink>
			  <NavLink className='link-line' to="/threeday-option" state={state}>
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i>   {tCommon("navigation.threeDay")}
              </li>
			  </NavLink>
			  <NavLink className='link-line' to="/fiveday-option" state={state}>
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i>   {tCommon("navigation.fiveDay")}
              </li>
			  </NavLink>
            </ul>
          </nav>
    </Menu>
				<div className=''>
				
				<div className='content-section-sa'>
				
				<div className='intro-sa'>
    <h2 className='ac-title-text-1'>{t('selection.title')}</h2>
    <p className='ac-p-text'>{t('selection.subTitle')} </p>
  

</div>

				

				<div className="option-card-container">
      <div className="option-card">
        <h3 className="option-texthead">{t('selection.one.title')}</h3>
        <p className="option-text"> {t('selection.one.subTitle')} </p>
		<NavLink to="/oneday-option" state={state}>
                    <button className='option-btn'> {t('selection.one.buttonText')} 
                    </button>
                    </NavLink>
      </div>
      <div className="option-card">
        <h3 className="option-texthead">{t('selection.two.title')} </h3>
        <p className="option-text">{t('selection.two.subTitle')}</p>
		
		<div >
		<NavLink to="/threeday-option" state={state}>
                    <button className='option-btn'>{t('selection.two.buttonText')}
                    </button>
                    </NavLink>
					</div>
      </div>
      <div className="option-card">
        <h2 className="option-texthead">{t('selection.three.title')}</h2>
        <p  className="option-text"> {t('selection.three.subTitle')}</p>
		<NavLink to="/fiveday-option" state={state}>
                    <button className='option-btn'> {t('selection.three.buttonText')}
                    </button>
                    </NavLink>
      </div>
    </div>
	</div>
				
			   
		</div>
		</div>	
		</div>		
				
			)
		}
	
	
	
	export default StudentTeacherActivities
